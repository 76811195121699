import React from 'react';
import { Text } from './text';

const Style = {
    container: 'flex flex-wrap w-full',
};

export const Tag = (comment) => {
    return (
        <div className={Style.container}>
            <span className="text-secondary-600">
                <Text {...comment} />
            </span>
        </div>
    );
};
