const Style = {
    title: 'text-xl font-semibold absolute sticky top-0 transform w-full text-center  text-white bg-main-500 px-4 py-1 rounded-b shadow',
    history: 'relative group/history flex flex-col bg-white rounded-xl shadow space-y-1 p-2 px-2 mb-4 space-x-4 w-[98%] max-w-[700px] mx-auto',
    submitBtn: `w-full h-10 px-5 text-white shadow-lg text-sm-bold transition-colors 
                duration-150 bg-vibrant-300 rounded-lg 
                focus:shadow-outline hover:bg-vibrant-500`,
    from: 'text-xs text-gray-500 bottom-1 right-3 mr-2',
    name: 'text-md font-semibold',
    text: 'flex flex-grow ml-[5%] whitespace-nowrap',
    about: 'flex items-center space-x-1',
    details: 'flex items-center space-x-1',
    editIcon: 'shadow-sm group/edit invisible group-hover/history:visible hover:cursor-pointer transition duration-300 ease-in-out',
    editInfo: 'absolute bottom-1 right-3 mr-2 mb-2',
    assigned: 'flex items-center space-x-1 text-secondary-600 text-sm',
    icon: 'w-4 h-4 red-500',
    general: 'flex items-center justify-between ml-2',
    date: 'text-sm text-gray-500 whitespace-nowrap',
    comment: 'py-2 flex-col space-y-2',
    info: 'flex space-x-2 items-center',
    divider: 'border-t my-1 border-slate-300',
}

export default Style