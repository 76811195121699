import React from 'react';
import { Icon } from 'components/lib';
import Style from './filesViewer.tailwind';
import { formatFileName } from 'utilities/utils';

export const FilesViewer = (props) => {
    const { files } = props;

    return (
        <div className={Style.filesViewerContainer}>
            {files?.map((file, index) => {
                const type = file.type.split('/')[0];
                return(
                    <div className={Style.fileWrapper} key={index}>
                        <button type="button" onClick={() => props.onRemoveFile(index)} className={Style.closeButton}>
                            <Icon
                                image={'x'}
                                color={'white'}
                                size={18}
                            />
                        </button>
                        
                        {type === "image" ? (
                            <Image key={index} file={file} onRemoveFile={() => props.onRemoveFile(index)} />
                        ) : (
                            <Other key={index} file={file} onRemoveFile={() => props.onRemoveFile(index)} />
                        )}            
                    </div>
                )
            })}
        </div>
    );
};

const Image = (props) => {
    const { file } = props;
    return (
        <div className={Style.imageWrapper} title={file.name}>
            <img src={props.file.url} alt={file.name} className={Style.image}/>
            <button type="button" onClick={props.onRemoveFile} className={Style.closeButton}>
                <Icon
                    image={'x'}
                    color={'white'}
                    size={18}
                />
            </button>
        </div>
    );
}

const Other = (props) => {
    const { file } = props;
    return (
        <div className={Style.textWrapper} title={file.name}>
            {formatFileName(file.name)}
           <button type="button" onClick={props.onRemoveFile} className={Style.closeButton}>
                <Icon
                    image={'x'}
                    color={'white'}
                    size={18}
                />
            </button>
        </div>
    );
}