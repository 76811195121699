// History Style
const Style = {
    wrapper: 'flex flex-col h-full overflow-hidden border-t ',
    container: 'flex flex-col justify-between p-0 h-full bg-slate-200',
    title: 'text-xl font-semibold absolute sticky top-0 transform w-full text-center px-4 py-1 rounded-b shadow',
    history: 'w-[95%] max-w-[700px] mx-auto',
    histories: 'flex-1 overflow-y-auto mt-12',    
}


export default Style