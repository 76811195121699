import React from 'react';
import { useTranslate } from 'app/translations';

const Style = {
    container: 'flex flex-wrap w-full',
};

export const Description = (comment) => {
    const { _t } = useTranslate();
    return (
        <div className={Style.container}>
            <span className="text-secondary-600">
                {_t('ticket.history.event.description.updated').toLowerCase()}
            </span>
        </div>
    );
};
