import React from 'react';
import Style from './file.tailwind';
import { Icon } from 'components/lib';
import { useTranslate } from 'app/translations';
import { formatFileName } from 'utilities/utils';

const TicketFile = ({ medias }) => {
    const { _t } = useTranslate();
    const noMedias = medias.length === 0;
    const multipleMedias = medias.length > 1;
    return (
        <div className={Style.wrapper}>
            <h3 className={Style.title}>
                <Icon image="paperclip" className={Style.icon} size={20} />
                {_t('ticket.history.file')}{multipleMedias && "s"}
            </h3>
            <div className={Style.container}>
                {noMedias ? <p>{_t('ticket.history.noFilesAttached')}</p> : (
                    medias.map((media, index) => (
                        <div key={index} className={Style.media}>
                            <a target="_blank" rel="noreferrer" href={media.file_path} title={media.file.original_name}>
                                {formatFileName(media.file.original_name)}
                            </a>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default TicketFile;
