import { _t } from '../app/translations';
import { ProjectMagic } from 'views/auth/signin/projectMagic';

const Routes = [
    {
        path: '/project/:name/magic',
        view: ProjectMagic,
        layout: 'auth',
        title: _t('signin.label'),  
    },
];

export default Routes;