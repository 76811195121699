/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React from 'react';
import { Hero } from 'components/lib';
import { getSubdomain } from 'utilities/routes';
import { AccountPage } from 'views/website/account/main';

export function Home(props) {
    let subdomain = getSubdomain(props);

    // TODO: render only the rest of the home page if no subdomain else render the organization langding page
    return (
        <div>
            {subdomain ? <AccountPage subdomain={subdomain} /> : <></>}
        </div>
    );
}
