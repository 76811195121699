const Style = {
    wrapper: 'absolute top-0 left-0 w-full h-full z-50',
    overlay: 'absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm',
    content: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 h-1/2 bg-white rounded-2xl shadow-lg p-12',
    top: 'flex flex-col justify-center items-center h-1/2',
    title: 'text-2xl font-semibold text-center mb-4',
    divider: 'w-full border-t border-slate-300 mb-8',
    text: 'text-center text-slate-500 text-lg',

}

export default Style;