// components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './auth';
import { View, ProjectContextProvider, BreadcrumbsContextProvider } from 'components/lib';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AppWebsocketContextProvider } from 'context/AppWebsocketContext';

// 404
import { NotFound } from 'views/error/404';

// tailwind css
import { _t } from './translations';
import '../css/input.css';
import { Redirect } from 'views/redirect';

// settings
const StripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const routes = [
    ...require('routes/settings').default,
    ...require('routes/app').default,
    ...require('routes/auth').default,
    ...require('routes/website').default,
    ...require('routes/tickets').default,
    ...require('routes/project').default,
    ...require('routes/contacts').default,
    ...require('routes/general').default,
];

export default function App(props) {
    return (
        <Elements stripe={StripePromise}>
            <BrowserRouter>
                <Routes>
                    {routes.map((route) => {
                        return (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={
                                    route.permission ? (
                                        <AppWebsocketContextProvider>
                                            <BreadcrumbsContextProvider>
                                                <ProjectContextProvider>
                                                    <PrivateRoute
                                                        permission={route.permission}
                                                        allowNotOnboarded={route.allowNotOnboarded}>
                                                        <View
                                                            display={route.view}
                                                            layout={route.layout}
                                                            title={route.title}
                                                        />
                                                    </PrivateRoute>
                                                </ProjectContextProvider>
                                            </BreadcrumbsContextProvider>
                                        </AppWebsocketContextProvider>
                                    ) : (
                                        <View
                                            display={route.view}
                                            layout={route.layout}
                                            title={route.title}
                                        />
                                    )
                                }
                            />
                        );
                    })}

                    {/* Redirect */}
                    <Route
                        path="/redirect"
                        element={
                            <View display={Redirect} layout="home" title={_t('redirect.loading')} />
                        }
                    />

                    {/* 404 */}
                    <Route
                        path="*"
                        element={<View display={NotFound} layout="home" title="404 Not Found" />}
                    />
                </Routes>
            </BrowserRouter>
        </Elements>
    );
}
