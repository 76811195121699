import Contacts from 'views/users/contacts';
import EditUser from 'views/users/edit';
import { _t } from '../app/translations';

const Routes = [
    {
        path: '/app/contacts',
        view: Contacts,
        layout: 'app',
        permission: 'admin',
        title: _t('contact.label.multiple'),
    },
    {
        path: '/app/contacts/:userId',
        view: EditUser,
        layout: 'app',
        permission: 'admin',
        title: _t('contact.label.one'),
    },
];

export default Routes;
