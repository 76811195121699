import { Label, Error, ClassHelper } from 'components/lib';
import Style from './selectGroup.tailwind.js';
import { useTranslate } from 'app/translations.js';

export function SelectGroup(props) {
    const { _t } = useTranslate();
    let options = props.options;
    const error = props.errorMessage || _t('option.required');
    let defaultOption = null;

    // set the default
    if (!props.default && options?.length) {
        // if theres no default, show a please select option
        if (options && options[0]?.value === 'unselected') {
            defaultOption = null;
        } else {
            defaultOption = { value: 'unselected', label: _t('option.required'), options: [] };
        }
    }

    function change(e) {
        let value = e ? e.target?.value : 'unselected';
        let valid = undefined;

        // validate
        valid = props.required && value === 'unselected' ? false : true;
        props.onChange(props.name, value, valid);
        props.callback && props.callback(value);
    }

    const wrapperStyle = ClassHelper(Style, {
        className: props.className,
        success: props.valid === true,
        errorWrapper: props.valid === false,
        warningWrapper: props.warning,
    });

    const selectStyle = ClassHelper(Style, {
        select: true,
        error: props.valid === false,
        warning: props.warning,
    });

    return (
        <div className={Style.input} key={props.default}>
            <Label text={props.label} required={props.required} for={props.name} />

            <div className={wrapperStyle}>
                <select className={selectStyle} onChange={(e) => change(e)} id={props.name}>
                    {defaultOption && (
                        <option key={defaultOption.value} value={defaultOption.value}>
                            {defaultOption.label}
                        </option>
                    )}
                    {options?.map((option, i) => {
                        return (
                            <optgroup label={option.label} key={i}>
                                {option.options.map((option, i) => {
                                    return (
                                        <option key={i} value={option.value}>
                                            {option.label}
                                        </option>
                                    );
                                })}
                            </optgroup>
                        );
                    })}
                </select>

                {props.valid === false && <Error message={error} className={Style.message} />}
            </div>
        </div>
    );
}
