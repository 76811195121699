import React from 'react';
import Style from './events.tailwind';
import { Due } from './due';
import { Assignee } from './assignee';
import { Status } from './status';
import { Name as NameEvent } from './name';
import { Priority } from './priority';
import { Description } from './description';
import { Created } from './created';
import { Section } from './section';
import { Sync } from './sync';
import { Time } from '../general/time';
import { Name } from '../general/name';
import { Attachment } from './attachment';

export const EventsComponent = {
    description: Description,
    assignee: Assignee,
    status: Status,
    due: Due,
    name: NameEvent,
    priority: Priority,
    created: Created,
    section: Section,
    sync: Sync,
    attachment: Attachment
};

const parseEvents = (history, user, ticket) => {
    const Component = EventsComponent[history?.action_type.split('_')[0]];
    if (!Component) return null;
    return (
        <Component
            actionType={history.action_type}
            historyData={history.history_data}
            user={user}
            ticket={ticket}
        />
    );
};

export const EventsHistory = ({ history, user, ticket }) => {
    return (
        <div className={Style.history}>
            <div className={Style.container}>
                <div className={Style.info}>
                    <span className={Style.name}>
                        <Name target={history.creator_name} user={user} />
                    </span>
                    <div className={Style.event}>
                        {parseEvents(history, user, ticket)}
                    </div>
                </div>
                <Time date={history.date_created} className={Style.date} />
            </div>
        </div>
    );
};
