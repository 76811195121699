const Style = {

  file: 'mb-4',
  dropzone: 'relative text-center overflow-y-auto overflow-x-hidden h-32 px-2 py-4 border border-slate-300 rounded-full',
  dragging: 'rounded-2xl border',
  success: 'border border-dashed border-emerald-500',
  label: 'absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 overflow-hidden',
  labelIcon: 'inline-block',
  labelText: 'relative inline-block text-xs',
  legacyInput: 'hidden',
  error: 'mb-0 border border-dashed border-red-500 bg-red-50',
  fileListItem: 'inline-block relative mt-2mr-2 text-sm',
  fileListButton: 'absolute -left-1 w-1 h-1',
  image: '-mb-12 shadow-lg absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rounded-full object-cover',

}

export default Style;