import { _t } from 'app/translations';

export function validateDomain(e) {
    const { value } = e.target;

    if (value) {
        // check if it contains a dot
        if (value.indexOf('.') === -1) {
            return _t('domain.error.dot');
        }
        // check if special characters are present except for dot
        const regex = new RegExp(/^[a-zA-Z0-9.]*$/);

        if (!regex.test(value)) {
            return _t('domain.error.special');
        }
    } else {
        return _t('domain.empty');
    }
}

export function validateEmail(e) {
    const { value } = e.target;

    if (!value) return _t('email.empty');

    const regex = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    if (!regex.test(value)) {
        return _t('email.invalid');
    }
}