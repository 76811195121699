import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useApiContext } from 'app/api';
import { Animate, Card, TitleRow, Table, ViewContext, useNavigate } from 'components/lib';
import { useTranslate } from 'app/translations';

const ProjectUsers = (props) => {
    const { projectUsers, users, project: project_name } = props;

    // Hooks
    const { id } = useParams();
    const { callApi } = useApiContext();
    const { _t } = useTranslate();
    const navigate = useNavigate();

    // Context
    const context = useContext(ViewContext);

    const [usersData, setUsersData] = useState([]);

    useEffect(() => {
        if (!users.data || !projectUsers.data) return;

        setUsersData(
            users.data?.data.users.map((user) => {
                if (user.permission === 'owner') return user;

                const projectUser = projectUsers.data.users.find(
                    (u) => u.user_id === user.id && u.permission !== 'owner',
                );

                return {
                    ...user,
                    role: projectUser?.role && _t(`permissions.${projectUser?.role}`),
                    active: !!projectUser,
                };
            }),
        );
    }, [users, projectUsers]);

    const usersFetched = users.data?.data.users && projectUsers.data?.users;

    // Actions function

    const { mutate: addProjectUser } = useMutation(
        (data) =>
            callApi({
                url: `/api/project/${id}/user`,
                method: 'POST',
                data,
            }),
        {
            onSuccess: () => {
                projectUsers.refetch();
            },
        },
    );

    function addUser(data) {
        addProjectUser({
            user_id: data.id,
            project_id: id,
        });
    }

    function edit(user) {
        navigate(`/app/settings/users/${user.id}`, {
            state: { title: user.first_name, project_name },
        });
    }

    function remove(user) {
        // remove user from project
        context.modal.show(
            {
                title: _t('project.user.remove.label'),
                form: {},
                buttonText: _t('confirm'),
                text: _t('project.user.remove.text'),
                url: `/api/project/${id}/user`,
                method: 'DELETE',
                destructive: true,
                injectedData: {
                    user_id: user.id,
                    project_id: id,
                },
            },
            (res) => {
                if (res.status === 200) {
                    projectUsers.refetch();
                }
            },
        );
    }

    if (users.isLoading || projectUsers.isLoading) return null;

    return (
        <Animate>
            <Card>
                <TitleRow title={_t('user.manage.label.multiple')} />
                {usersFetched && (
                    <Table
                        search
                        className="restrict-width"
                        data={usersData}
                        loading={projectUsers.isLoading}
                        show={['first_name', 'last_name', 'email', 'role']}
                        actions={{
                            custom: [
                                {
                                    icon: 'edit',
                                    action: edit,
                                    title: _t('project.user.update'),
                                    conditions: [
                                        { col: 'active', value: true },
                                        {
                                            col: 'role',
                                            value: [
                                                _t(`permissions.admin`),
                                                _t('permissions.contact'),
                                                _t('permissions.owner'),
                                            ],
                                        },
                                    ],
                                },
                                {
                                    icon: 'user-minus',
                                    action: remove,
                                    title: _t('project.user.remove'),
                                    conditions: [
                                        { col: 'active', value: true },
                                        {
                                            col: 'role',
                                            value: [
                                                _t(`permissions.admin`),
                                                _t('permissions.contact'),
                                            ],
                                        },
                                    ],
                                },
                                {
                                    icon: 'plus',
                                    action: addUser,
                                    title: _t('project.user.add'),
                                    conditions: [{ col: 'active', value: false }],
                                },
                            ],
                        }}
                    />
                )}
            </Card>
        </Animate>
    );
};

export default ProjectUsers;
