import ClickupIntegrationConnector from 'components/tickets/integration/clickup/integrationConnector';
import { _t } from 'app/translations';
import ExistingCategoryConnector from 'components/tickets/integration/clickup/existingCategoryConnector.js';
import SlackItemContent from 'components/integrations/slack/SlackItemContent';
import getSlackFormData from 'components/integrations/slack/getSlackFormData';

const integrationsConfig = {
    clickup: {
        name: 'Clickup',
        item: {
            content: (integration) => {
                return (
                    <div class="flex flex-col">
                        <p>
                            <strong className="mr-2">Information:</strong>
                            {integration.integration_data?.team_name}
                        </p>
                        <p>
                            <strong className="mr-2">{_t('createdBy')}:</strong>
                            {integration.integration_data?.created_by}
                        </p>
                    </div>
                );
            },
        },
        form: () => {
            return null;
        },
        ticket: {
            category: {
                create: {
                    label: _t('clickup.list'),
                    connector: () => {
                        return (
                            <ClickupIntegrationConnector
                                method="POST"
                                url="/api/ticket/category"
                                navigateToCategory
                            />
                        );
                    },
                },
                clone: {
                    label: _t('categories.existing.one'),
                    connector: ExistingCategoryConnector,
                },
                general: {
                    integration: ClickupIntegrationConnector,
                },
                import: {
                    label: _t('clickup.space'),
                    connector: () => {
                        return (
                            <ClickupIntegrationConnector
                                method="POST"
                                connectTo="space"
                                url="/api/integration/clickup/tickets/f/importCategoryFromSpace"
                            />
                        );
                    },
                },
            },
        },
    },
    slack: {
        name: 'Slack',
        item: {
            content: (props) => {
                return <SlackItemContent {...props} />;
            },
        },
        form: async (integration) => {
            return await getSlackFormData(integration);
        },
    },
};

export default integrationsConfig;
