import React from 'react';
import { Row, Content, Message } from 'components/lib';
import { useTranslate } from 'app/translations';

export function NoProjects(props) {
  const { _t } = useTranslate();
  return(
    <Row>
      <Content>
        <Message
          type='error'
          title={_t('error.label')}
          text={_t('error.project.empty')}
          buttonText={_t('message.home')}
          buttonLink='/'
        />
      </Content>
    </Row>
  );
}
