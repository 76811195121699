import React from 'react';
import Style from './dropdown.tailwind';

const Dropdown = (props) => {
    const { list } = props;
     return (
        <div className={Style.dropdown}>
            {list.map((item, index) => {
                return (
                    <div key={index} className={Style.item} onClick={item.action}> 
                        {item.label}
                    </div>
                );
            })}
        </div>
    );
};

export default Dropdown;
