/***
 *
 *   SUB NAV
 *   Sub navigation element (located underneath the header).
 *
 **********/

import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from 'app/auth';
import { Icon } from 'components/lib';
import './sub.scss';
import { useLocation, Link } from 'react-router-dom';

export function SubNav(props) {
    const context = useContext(AuthContext);
    const location = useLocation();
    return (
        <nav className="subnav">
            {props.items?.map((item) => {
                if (item.permission && !context.permission[item.permission]) return false;

                if (item.link !== location.pathname && item.exact) {
                    return (
                        <Link key={item.label} to={item.link} className="item">
                            {item.label}
                        </Link>
                    );
                } else
                    return (
                        <NavLink
                            key={item.label}
                            to={item.link}
                            title={item?.alert?.message}
                            className="item">
                            {item.alert && (
                                <Icon
                                    image={'alert-circle'}
                                    color={'red'}
                                    size={13}
                                    className='alert'                                    
                                />
                            )}
                            {item.label}
                        </NavLink>
                    );
            })}
        </nav>
    );
}
