const Style = {
    container: 'min-h-[150px] pt-5 w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden shadow-inner',
    link: 'text-blue-500 underline',
    textArea: `w-full h-20 px-3 py-2 text-base resize-none text-gray-700 placeholder-gray-600 
                border rounded-lg focus:outline-none 
                focus:ring-1 focus:ring-vibrant-300 transition-shadow 
                duration-200 ease-in-out placeholder:italic placeholder:text-slate-400`,
    placeholder: 'text-gray-400 text-sm opacity-50',
    submitBtn: `h-10 px-5 text-white shadow-lg text-sm-bold transition-colors 
                duration-150 bg-vibrant-300 rounded-lg 
                focus:shadow-outline hover:bg-vibrant-500 whitespace-nowrap w-auto`,  
}

export default Style;