import React, { useState, useRef, useEffect, useContext } from 'react';
import Style from './history.tailwind';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';
import { CommentsHistory } from '../history/comments/comments';
import { EventsHistory } from '../history/events/events';
import { Chat } from '../history/chat/chat';

function TicketHistory(props) {
    const { _t } = useTranslate();
    const { ticket } = props;
    const { user } = useContext(AuthContext);
    const { histories } = ticket;    

    const lastCommentRef = useRef(null);

    const renderHistories = histories?.map((history, index) => {
        const type = (history.action_type === "comment_posted" || history.action_type === "comment_updated") ? "comment" : "event";
        const HistoryComponent = (type === 'comment') ? CommentsHistory : EventsHistory;

        const historyProps = {
            history,
            ticket,
            user,
        }

        return (
            <div 
                key={index}
                ref={index === histories.length - 1 ? lastCommentRef : null} 
                className={Style.history}
            >
                <HistoryComponent {...historyProps} />
            </div>
        )
    });

    useEffect(() => {
        if(lastCommentRef.current) {
            lastCommentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [histories.length]);

    return (
        <div className={Style.wrapper}>
            <h3 className={Style.title}>{_t('history')}</h3>
            <div className={Style.container}>
                <div className={Style.histories}>
                    {histories.length !== 0 ? renderHistories : (
                        <div className={Style.history}>
                            <span>{_t('ticket.history.noHistory')}</span>
                        </div>
                    )}
                </div>
                <Chat ticket={ticket} />
            </div>
 
        </div>
    );
};

export default TicketHistory;
