/***
*
*   usePermissions hook
*   fetch, format and return the user permissions
*
**********/

import { useState, useEffect } from 'react';
import { useApiContext } from 'app/api';
import { useQuery } from 'react-query';

export function usePermissions(){
  const { callApi } = useApiContext();
  const [state, setState] = useState({ data: null, loading: false });
  const permissions = useQuery('permissions', () => callApi({url: '/api/user/permissions' }));

  useEffect(() => {

    setState({ loading: true });
    // format permissions
    if (permissions.data?.data){

      let perms = [];
      Object.keys(permissions?.data.data).map(perm => {

        perms.push({ value: perm, label: perm });
        setState({ data: { list: perms }, loading: false });
        return perms;

      });
    }
  }, [permissions.data]);

  return state;

}
