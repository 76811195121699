/***
 *
 *   PROFILE
 *   Update the user profile
 *
 **********/

import React, { useContext } from 'react';
import { Form, Card, SettingsWrapper, Animate } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';
import { useApiContext } from 'app/api';
import { useQuery } from 'react-query';

export function Profile(props) {
    const { _t } = useTranslate();

    // context
    const { callApi } = useApiContext();

    const authContext = useContext(AuthContext);

    // fetch
    const user = useQuery('user', () => callApi({ url: '/api/user' }));
    
    return (
        <SettingsWrapper>
            <Animate>
                <Card title={_t('profile.edit.text')} loading={user.isLoading} centerFit>
                    {user?.data && (
                        <Form
                            buttonText={_t('save')}
                            url="/api/user"
                            method="PATCH"
                            data={{
                                first_name: {
                                    label: _t('firstName.title'),
                                    type: 'text',
                                    required: true,
                                    value: user.data.data.first_name,
                                    errorMessage: _t('firstName.empty'),
                                },
                                last_name: {
                                    label: _t('lastName.title'),
                                    type: 'text',
                                    required: true,
                                    value: user.data.data.last_name,
                                    errorMessage: _t('lastName.empty'),
                                },
                                email: {
                                    label: _t('email.yourEmail'),
                                    type: 'email',
                                    required: true,
                                    value: user.data.data.email,
                                    errorMessage: _t('email.empty'),
                                },
                                language: {
                                    label: _t('language.title'),
                                    type: 'select',
                                    required: true,
                                    value: user.data.data.language,
                                    options: [
                                        { value: 'en', label: _t('language.english') },
                                        { value: 'fr', label: _t('language.french') },
                                    ],
                                },
                                ...(user.data.data?.accounts?.length > 1 && {
                                    default_account: {
                                        label: _t('organization.default'),
                                        type: 'select',
                                        default: user.data.data.default_account,
                                        options: user.data.data.accounts.map((x) => {
                                            return {
                                                value: x.id,
                                                label: x.name,
                                            };
                                        }),
                                    },
                                }),
                            }}
                            callback={(res) => {
                                // update the user name
                                authContext.update({
                                    ...res.data,
                                });

                                // reload the page if the language has changed
                                if(res.data.language !== user.data.data.language) {
                                    window.location.reload();
                                }
                            }}
                        />
                    )}
                </Card>
            </Animate>
        </SettingsWrapper>
    );
}
