export const buildQueryParams = (params) => {
    const paramsArray = Object.entries(params);
    const queryParams = paramsArray.reduce((acc, [key, value]) => {
        value = value?.replace?.(/ /g, '+');
        if (value) {
            acc.push(`${key}=${value}`);
        }
        return acc;
    }, []);

    if (queryParams.length) {
        return `?${queryParams.join('&')}`;
    } else {
        return '';
    }
};
