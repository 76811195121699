import { useQuery } from 'react-query';
import { useApiContext } from 'app/api';
import { useLiveQuery } from 'components/hooks/websocket.js';

function splitProjectUsersType(projectUsers) {
    let contacts = projectUsers.filter((user) => user.is_contact);

    // make sure we only have unique contacts
    contacts = contacts.filter((contact, index) => {
        return contacts.findIndex((c) => c.user_id === contact.user_id) === index;
    });

    const users = projectUsers.filter((user) => !user.is_contact);

    return { contacts, users };
}

export function useProjectUsers(id) {
    const query = useLiveQuery('project_user', {
        ...(id && { id }),
    });

    const splittedProjectUsers = splitProjectUsersType(query.data || []);

    return {
        ...query,
        data: splittedProjectUsers,
    };
}

export function useUsers(id) {
    const { callApi } = useApiContext();
    const projectUsers = useProjectUsers(id);

    const filterInvites = id
        ? (invite) => invite.project_id === id && !invite.used
        : (invite) => !invite.used;

    const filterAvailableContacts = (user) =>
        user.is_contact && !projectUsers.data?.contacts?.find((pUser) => pUser.user_id === user.id);

    const filterUsers = (user) => !user.is_contact;

    const filterUsersInvites = (invite) => invite.permission !== 'contact' && !invite.used;

    return useQuery('users', () => callApi({ url: '/api/account/users' }), {
        select: (res) => {
            if (!res.data) return;

            const { data } = res;
            return {
                ...res,
                data: {
                    users: data.users?.filter(filterUsers) || [],
                    invites: data.invites?.filter(filterInvites) || [],
                    availableContacts: data.users?.filter(filterAvailableContacts) || [],
                    usersInvites: data.invites?.filter(filterUsersInvites) || [],
                },
            };
        },
    });
}
