import Tickets from 'views/tickets/tickets';
import { _t } from '../app/translations';
import TicketsCategory from 'views/tickets/category/ticketsCategory';
import TicketsCategoryForm from 'views/tickets/category/ticketsCategoryForm';
import TicketsCategoryEmail from 'views/tickets/category/ticketsCategoryEmail';
import TicketsCategoryLayout from 'components/layout/tickets/ticketsCategory';
import TicketsCategoryStatus from 'views/tickets/category/ticketsCategoryStatus';


const Routes = [
    {
        path: '/app/tickets',
        view: Tickets,
        layout: 'app',
        permission: 'user',
        title: _t('ticket.label.multiple'),
    },
    {
        path: '/app/tickets/category/:id',
        view: () => (
            <TicketsCategoryLayout>
                <TicketsCategory />
            </TicketsCategoryLayout>
        ),
        layout: 'app',
        permission: 'user',
        title: _t('ticket.category.label'),
    },
    {
        path: '/app/tickets/category/:id/form',
        view: () => (
            <TicketsCategoryLayout>
                <TicketsCategoryForm />
            </TicketsCategoryLayout>
        ),
        layout: 'app',
        permission: 'user',
        title: _t('ticket.category.form'),
    },
    {
        path: '/app/tickets/category/:id/email',
        view: () => (
            <TicketsCategoryLayout>
                <TicketsCategoryEmail />
            </TicketsCategoryLayout>
        ),
        layout: 'app',
        permission: 'user',
        title: _t('ticket.category.email'),
    },
    {
        path: '/app/tickets/category/:id/status',
        view: () => (
            <TicketsCategoryLayout>
                <TicketsCategoryStatus />
            </TicketsCategoryLayout>
        ),
        layout: 'app',
        permission: 'user',
        title: _t('ticket.category.status.label'),
    },
];

export default Routes;
