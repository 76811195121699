/***
*
*   HEADER
*   Header section with title used in main application (can render children)
*
*   PROPS
*   title: title of the view
*   children: children to render (optional)
*
**********/


import { Breadcrumbs } from 'components/lib.js';
import Style from './header.tailwind.js';

export function Header(props){

  return (
    <header className={ Style.header }>

      { props.title && <Breadcrumbs title={props.title}/> }

      { props.children }

    </header>

  );
}
