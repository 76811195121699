/***
*
*   HELP
*   Information for user to get help and support
*
**********/

import React, { Fragment } from 'react';
import { Card, Form, Message, Loader } from 'components/lib';
import { useTranslate } from 'app/translations';
import { useApiContext } from 'app/api';
import { useQuery } from 'react-query';

export function Help(props){
  const { _t } = useTranslate();
  const { callApi } = useApiContext();
  const user = useQuery('user', () => callApi({ url: '/api/user' }));

  return (
    <Fragment>

      <Message 
        title={_t('help.need')}
        type='info'
        text={_t('message.supportInfo')}
      />

      { user.isLoading ? 
        <Loader /> :
        <Card title={_t('contact.support')} restrictWidth>      
          <Form 
            data={{
              support_enabled: {
                type: 'radio',
                options: [_t('yes'), _t('no')],
                required: true,
                errorMessage: _t('formMessage.empty'),       
                default: user.data?.data.support_enabled ? _t('yes') : _t('no') ,
                label: _t('contact.accessInfo'),
              }
            }}
            submitOnChange
            method='PATCH'
            url='/api/user'
          />
          <Form 
            data={{
              email: {
                type: 'hidden',
                value: user?.data?.data.email,
              },
              name: {
                type: 'hidden',
                value: user?.data?.data.name
              },
              template: { 
                type: 'hidden', 
                value: 'help',
              },
              message: {
                type: 'textarea',
                label: _t('help.question'),
                errorMessage: _t('formMessage.empty'),
                required: true,
              }
            }}
            method='POST'
            url='/api/utility/mail'
            buttonText={_t('formMessage.send')}
          />
      </Card>
     }
    </Fragment>
  )
}