/***
 *
 *   BILLING
 *   Change subscription, update card details or view past invoices
 *
 **********/

import React, { useContext } from 'react';
import { SettingsWrapper, TabView, Message, Animate } from 'components/lib';
import { AuthContext } from 'app/auth';
import { BillingPlan } from './plan';
import { BillingCard } from './card';
import { BillingInvoices } from './invoices';
import { useTranslate } from 'app/translations';
import { useApiContext } from 'app/api';
import { useQuery } from 'react-query';

const MessagesEN = require('./messages_en.json');
const MessagesFR = require('./messages_fr.json');

export function Billing(props) {
    const { locale } = useTranslate();
    const { callApi } = useApiContext();

    let Messages = MessagesEN;

    if (locale === 'fr') {
        Messages = MessagesFR;
    }
    // state/context
    const context = useContext(AuthContext);

    // fetch subscription
    const subscription = useQuery('subscription', () =>
        callApi({ url: '/api/account/subscription' }),
    );

    const isPaid = context.user.plan === 'free' ? false : true;
    const labels = ['Plan'];
    if (isPaid) labels.push('Card', 'Invoices');

    return (
        <SettingsWrapper>
            <Animate>
                {!subscription?.isLoading &&
                    subscription?.data?.status !== 'active' &&
                    subscription?.data?.status !== 'trialing' && (
                        <Message {...Messages[subscription.data?.status]} />
                    )}

                <TabView name="Billing" labels={labels}>
                    <BillingPlan subscription={subscription} />

                    {isPaid && <BillingCard />}

                    {isPaid && <BillingInvoices />}
                </TabView>
            </Animate>
        </SettingsWrapper>
    );
}
