/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext } from 'react';
import { Animate, Row, Card, SocialSignin, Form, Link } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';

export function Signup(props) {
    const { _t } = useTranslate();
    const context = useContext(AuthContext);

    return (
        <Animate type="pop">
            <Row title={_t('user.create.title')}>
                <Card loading={false} restrictWidth center>
                    {/* <SocialSignin network={['facebook', 'twitter']} showOr signup /> */}

                    <Form
                        data={{
                          first_name: {
                            label: _t('firstName.label'),
                            value: '',
                            type: 'text',
                            required: true,
                            errorMessage: _t('firstName.empty'),
                          },
                          last_name: {
                            label: _t('lastName.label'),
                            value: '',
                            type: 'text',
                            required: true,
                            errorMessage: _t('lastName.empty'),
                          },
                            email: {
                                label: _t('email.label'),
                                type: 'email',
                                required: true,
                            },
                            password: {
                                label: _t('password.label'),
                                type: 'password',
                                required: true,
                                complexPassword: true,
                            },
                            confirm_password: {
                                type: 'hidden',
                                value: null,
                            },
                        }}
                        url="/api/account"
                        method="POST"
                        auth={false}
                        buttonText={_t('user.create.label')}
                        callback={context.signin}
                    />

                    <div className="mt-4">
                        {_t('signup.already')} <Link url="/signin" text={_t('signin.label')} />
                    </div>
                </Card>
            </Row>
        </Animate>
    );
}
