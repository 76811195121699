import React from 'react';
import { priorityColor } from 'components/tickets/ticket';
import { useTranslate } from 'app/translations';

const Style = {
    container: 'flex flex-wrap w-full',
};

const PriorityInfo = ({ priority, styleClasses }) => {
    return (
        <span className={`flex justify-center items-center mx-1 px-1 ${styleClasses}`}>
            {priority}
        </span>
    );
};

export const Priority = ({ historyData }) => {
    const { _t } = useTranslate();
    const { before, after } = historyData;
    return (
        <div className={Style.container}>
            {before && after ? (
                <p className="flex gap-px">
                    {_t('ticket.history.event.priority.updated').toLowerCase()}
                    <PriorityInfo priority={before} styleClasses={priorityColor[before]} /> 
                    {_t('to').toLowerCase()}
                    <PriorityInfo priority={after} styleClasses={priorityColor[after]} />
                </p>
            ) : !before && after ? (
                <p className="flex gap-px">{_t('ticket.history.event.priority.set').toLowerCase()} <PriorityInfo priority={after} styleClasses={priorityColor[after]} /></p>
            ) : before && !after && (
                <p>{_t('ticket.history.event.priority.deleted').toLowerCase()}</p>
            )}
        </div>
    );
};
