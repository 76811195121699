import settings from '../settings';

const { client_url_full, client_url_main } = settings;

export const navigateRoot = (path = '') => {
    window.location.href = `${client_url_full}${path}`;
};

export const navigateSubDomain = (domain, path = '') => {
    window.location.href = `${buildSubDomainPath(domain)}${path}`;
}

export const rootPath = (path) => {
    return `${client_url_full}${path}`;
};

export const getSubdomain = () => {
    const subdomain =  window.location.hostname.split('.')[0];

    if(['www', 'api', 'valetify', 'dev-valetify'].includes(subdomain)) return null;
    return subdomain;
};

export const buildSubDomainPath = (domain) => {
    let basePath = 'http://';

    // Add subdomain
    if (domain) basePath += domain;

    // Add the domain
    basePath += '.' + client_url_main;

    return basePath;
};
