import { useAppWebsocketContext } from 'context/AppWebsocketContext';
import { useEffect, useRef } from 'react';
import { liveData } from 'config/websocket';
import { useApiContext } from 'app/api';
import { useQuery, useQueryClient } from 'react-query';
import { singleRealtimeUpdate, listRealtimeUpdate } from 'websocket/helpers';
import { getUserCache } from 'utilities/cache';

/**
 * Give access to the realtime updates of a schema
 * @param {*} schemaKey
 * @param {*} onUpdate
 * @param {*} onDisconnect
 * @param {*} onConnect
 */
export function useRealtimeUpdates(schemaKey, onUpdate, onDisconnect, onConnect) {
    const { connectToChannel, disconnectFromChannel } = useAppWebsocketContext();
    const schemaKeyRef = useRef(null);

    // Store the methods in a ref, so they can be accessed in the useEffect
    const methodsRef = useRef({});

    methodsRef.current.onUpdate = onUpdate;
    methodsRef.current.onDisconnect = onDisconnect || (() => {});
    methodsRef.current.onConnect = onConnect || (() => {});

    useEffect(() => {
        schemaKeyRef.current = connectToChannel(schemaKey, methodsRef.current);

        return () => {
            disconnectFromChannel(schemaKeyRef.current);
        };
    }, [schemaKey]);
}

const defaultConfig = {
    onDelete: () => {},
    onUpdate: () => {},
    onCreate: () => {},
    onConnect: () => {},
    onDisconnect: () => {},
};

/**
 * Hook to get live data from the server
 * @param {*} key string key of the data and channel
 * @param {*} params object of params to send to the api or can be used to override the apiUrl or used to generate the websocketKey
 * @param {*} config object of functions or properties like in react-query
 * @returns {Object} queryResult
 */

export const useLiveQuery = (key, params = {}, config = {}) => {
    const { apiUrl, websocketKey, type } = liveData[key];
    const { callApi } = useApiContext();
    const queryClient = useQueryClient();
    const authData = getUserCache();

    config = { ...defaultConfig, ...config };

    params = { ...authData, ...params };

    const queryResult = useQuery(
        [key, params],
        ({ queryKey }) => {
            const [key, params] = queryKey;

            if (config.enabled === false) return;
            return callApi({ url: params.url || apiUrl(params) });
        },
        config,
    );
    const queryData = queryResult.data?.data || queryResult.data;

    const setData = (oldData, newData) => {
        return {
            ...oldData,
            data: newData,
        };
    };

    useRealtimeUpdates(websocketKey(params), (payload) => {
        const { method, data } = payload;
        if (type === 'list') {
            listRealtimeUpdate(payload, queryData, (data) => {
                queryClient.setQueryData([key, params], (old) => {
                    return setData(old, data);
                });
            });
        } else if (type === 'single') {
            singleRealtimeUpdate(payload, queryData, (data) => {
                queryClient.setQueryData([key, params], (old) => {
                    return setData(old, data);
                });
            });
        }

        const methodName = 'on' + method[0].toUpperCase() + method.slice(1);

        if (config[methodName]) {
            config[methodName](data);
        }
    });

    return {
        ...queryResult,
        data: queryData,
    };
};
