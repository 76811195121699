import React, { useState } from 'react';
import { useTranslate } from 'app/translations';

export const Name = (props) => {
    const { _t } = useTranslate();
    const { user, target } = props;    

    const userFullName = `${user?.first_name} ${user?.last_name}`;
    const hasMoreInfo = typeof target === 'object';
    const targetName = hasMoreInfo ? `${target?.first_name} ${target?.last_name}` : target;

    const isSelf = targetName.toLowerCase() === userFullName.toLowerCase();
    const name = isSelf ? _t('ticket.history.you') : targetName;
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    
    const formattedName = (name.toLowerCase() === 'system') ? _t('system') : name;

    return ( 
        <span 
            className={`relative group`} 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
        >
           {formattedName}
           {(isHovered && hasMoreInfo && !isSelf) && (
                <div className="absolute z-10 p-2 mt-2 bg-white text-sm text-black rounded-lg shadow-md w-60">
                    <div className="font-bold">{targetName}</div>
                    {target?.email && (
                        <div>{_t('ticket.history.email')}: {target?.email}</div>
                    )}
                    {target?.profilePicture && (
                        <div className="my-2">
                            <img src={target?.profilePicture} alt="profile" className="rounded-full w-10 h-10"/>
                        </div>
                    )}
                </div>
            )}
        </span>
     )
}
