/***
 *
 *   CONTACTS
 *   Update the contacts or view the contacts list
 *
 **********/
import React, { Fragment, useEffect } from 'react';
import { Animate, useProjectContext } from 'components/lib';
import { useUsers, useProjectUsers } from '../../components/hooks/users';
import ProjectContacts from 'views/settings/projects/contacts';

const Contacts = () => {
    const { selectedProject, isAllProjectSelected } = useProjectContext();

    const projectUsers = useProjectUsers(selectedProject?.id);
    
    const users = useUsers(selectedProject?.id);

    useEffect(() => {
        users.refetch();
        projectUsers.refetch();

    }, [selectedProject, isAllProjectSelected]);

    return (
        <Fragment>
            <Animate>
                {!projectUsers.isLoading && !users.isLoading && (
                    <ProjectContacts users={users} projectUsers={projectUsers} />
                )}
            </Animate>
        </Fragment>
    );
};

export default Contacts;
