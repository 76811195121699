export function singleRealtimeUpdate(payload, setState, onDelete) {
    const { method, data } = payload;

    switch (method) {
        case 'delete':
            onDelete();
            break;
        default:
            setState(data);
    }
}

export function listRealtimeUpdate(payload, state, setState) {
    const { method, id, data } = payload;

    const newState = state ? [...state] : [];
    let index;

    // eslint-disable-next-line default-case
    switch (method) {
        case 'create':
            newState.unshift(data);
            break;
        case 'update':
            index = newState.findIndex((item) => item.id === id);

            if (index === -1) return console.error('Could not find item to update');
            
            newState[index] = data;
            break;
        case 'delete':
            index = newState.findIndex((item) => item.id === id);

            if (index === -1) return console.error('Could not find item to delete');

            newState.splice(index, 1);
            break;
        default:
            return console.error('You must provide a valid method for realtime updates');
    }

    setState(newState);
}
