import auth from './auth.json';
import form from './form.json';
import base from './base.json';

const fr = {
    ...auth,
    ...form,
    ...base,
};

export default fr;
