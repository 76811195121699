import React from 'react';
import { useApiContext } from 'app/api.js';
import { useQuery } from 'react-query';
import LetteredAvatar from 'lettered-avatar';
import Style from './main.tailwind.js';
import { navigateRoot } from 'utilities/routes.js';
import { Loader } from 'components/loader/loader.js';

export function AccountPage(props) {
    const { subdomain } = props;
    const { callApi } = useApiContext();

    const account = useQuery('accountDomains', () =>
        callApi({ url: `/api/account/domain/${subdomain}`, auth: false }),
    );

    // If the account is not found, redirect to root
    const accountNotFound = account.isSuccess && !account.data?.data?.account;

    if (accountNotFound) {
        navigateRoot('/404');
        return null;
    }
    
    const accountData = account.data?.data?.account;

    return (
        <div className={Style.wrapper}>
            {account.isLoading ? (
                <Loader />
            ) : (
                <>
                    <hr className={Style.hr} />
                    <div className={Style.logo}>
                        <LetteredAvatar
                            name={accountData.name}
                            imgSrc={accountData.file_path}
                            options={{ imgClass: 'w-24 h-24 object-cover border'}}
                        />
                    </div>
                    <h1 className={Style.title}>{accountData.name}</h1>
                </>
                /* TODO: add org description */
                /* TODO: add a buttons link for public projects */
            )}
        </div>
    );
}
