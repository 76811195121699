/***
*
*   PRICING
*   Modify the PricePlans component with your own features
*
**********/

import React from 'react';
import { Animate, Row, PricePlans } from 'components/lib';
import {useTranslate} from 'app/translations';

export function Pricing(props){
  const { _t } = useTranslate();

  return (
    <Animate type='pop'>
      <Row color='tint' align='center' title={_t('pricing.slogan')}>
        <PricePlans />
      </Row>
    </Animate>
  )
}
