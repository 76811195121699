/***
 *
 *   SIGN UP (user)
 *   Signup form for child users
 *
 **********/

import React, { useContext } from 'react';
import { Animate, Row, Card, Form } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';
import { useSearchParams } from 'react-router-dom';

export function SignupProject(props) {
    const { _t } = useTranslate();
    const context = useContext(AuthContext);
  
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const project = searchParams.get('project');
    const account = searchParams.get('account');
    
    return (
        <Animate type="pop">
            <Row title={_t('user.create.for', {
                name: project.split('')[0].toUpperCase() + project.slice(1),
            })}>
                <Card restrictWidth center>
                    <Form
                        data={{
                          first_name: {
                            label: _t('firstName.title'),
                            value: '',
                            type: 'text',
                            required: true,
                            errorMessage: _t('firstName.empty'),
                          },
                          last_name: {
                            label: _t('lastName.title'),
                            value: '',
                            type: 'text',
                            required: true,
                            errorMessage: _t('lastName.empty'),
                          },
                            email: {
                                label: _t('email.label'),
                                value: email,
                                type: 'email',
                                required: true,
                            },
                            password: {
                                label: _t('password.label'),
                                type: 'password',
                                required: true,
                                complexPassword: true,
                            },
                            confirm_password: {
                                type: 'hidden',
                                value: null,
                            },
                        }}
                        auth={false}
                        url="/api/user/project"
                        method="POST"
                        injectedData={{
                            account_id: account,
                            project_name: project,
                        }}
                        buttonText={_t('user.create.label')}
                        callback={context.signin}
                    />
                </Card>
            </Row>
        </Animate>
    );
}
