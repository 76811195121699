import { useState, useEffect } from 'react';
import { useProjectContext, Card, Form, Animate } from 'components/lib';
import { useTranslate } from 'app/translations';
import { validateDomain, validateEmail } from 'utilities/form';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

export function ProjectForm(props) {
    const { _t } = useTranslate();
    const paramsId = useParams();
    const projectId = JSON.parse(localStorage.getItem('project_id')) || props.id || paramsId.id;
    const { projects } = useProjectContext();
    const queryClient = useQueryClient();
    const project = props.providedProject || projects?.find((p) => p.id === projectId);
    const [formData, setFormData] = useState({});

    const domainField = {
        name: 'domain',
        slug: 'project_domain',
        type: 'textList',
        label: _t('project.privacy.domain.label'),
        url: `/api/project/private/field/${props.id}`,
        id: props.id,
        method: 'POST',
        required: false,
        placeholder: `Ex: ${_t('email.label').toLowerCase()}.com`,
        onBlur: validateDomain,
        ignoreOnSubmit: true,
    };

    const emailField = {
        name: 'email',
        slug: 'project_email',
        label: _t('project.privacy.email.label'),
        type: 'textList',
        url: `/api/project/private/field/${props.id}`,
        id: props.id,
        method: 'POST',
        required: false,
        placeholder: `Ex: ${_t('exemple')}@mail.com`,
        onBlur: validateEmail,
        ignoreOnSubmit: true,
    };

    const createFormData = () => {
        if (!project) return {};
        return {
            logo: {
                label: 'Logo',
                type: 'logo',
                required: false,
                placeholder: project?.file_path || '',
            },
            name: {
                label: _t('project.name'),
                type: 'text',
                required: true,
                value: project?.name || '',
            },
            privacy: {
                label: _t('project.privacy.label'),
                type: 'select',
                required: true,
                options: [
                    { value: 'private', label: _t('private') },
                    { value: 'public', label: _t('public') },
                ],
                default: project?.privacy || 'private',
                onChange: (value) => {
                    setFormData((prev) => {
                        if (value === 'private') {
                            prev.domain = domainField;
                            prev.email = emailField;
                        } else {
                            delete prev.domain;
                            delete prev.email;
                        }
                        return { ...prev };
                    });
                },
            },
        };
    };

    useEffect(() => {
        const projectForm = createFormData();
        if (project?.privacy === 'private') {
            projectForm.domain = domainField;
            projectForm.email = emailField;
        }
        setFormData(projectForm);
    }, [projectId, project]);
  
    if (!Object.keys(formData).length) return null;

    return (
        <Animate>
            <Card centerFit title={props.disableTitle ? null : project?.name}>
                <Form
                    data={formData}
                    updateOnChange
                    callback={(res) => {
                        if (res.status === 200) {
                            queryClient.invalidateQueries('projects');
                            props.callback && props.callback(res);
                        }
                    }}
                    buttonText="Save"
                    url={props.url}
                    method={props.method}
                />
                {props.children}
            </Card>
        </Animate>
    );
}
