import axios from 'axios';
import { getUserCache } from 'utilities/cache';
import { _t } from 'app/translations';
import settings from '../../../settings';

export default async function getSlackFormData(integration) {
    const user = getUserCache();
    const baseURL = settings.server_url;
    const initialData = await axios({
        method: 'get',
        url: baseURL + `/api/integration/${integration.id}/slack/f/getInitialData`,
        headers: {
            Authorization: `Bearer ${user.token}`,
            'accept-language': user?.language || window.navigator.language,
        },
    });
    const channels = initialData.data.data.channels;

    return {
        channel: {
            label: _t('slack.channel.label.multiple'),
            type: 'select',
            default: integration.integration_data.channel,
            options: channels.map((channel) => {
                return {
                    label: channel.name,
                    value: channel.id,
                };
            }),
        },
    };
}
