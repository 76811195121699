import React, { useState, useEffect } from 'react';
import Style from './popup.tailwind';

/***
 *
 *   POPUP
 *   Display an overlay popup with a custom child component, positionned absolute to       prevent overflow on the side menu. If you need to call MODAL component instead.
 *
 *   PROPS
 *   title: (optional)
 *   text: message to the user (optional)
 *
 **********/

export const Popup = (props) => {
    const [show, setShow] = useState(props.show);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    if (!show) return null;

    return (
        <div className={Style.wrapper}>
            <div
                className={Style.overlay}
                onClick={() => {
                    setShow(false);
                    if(props.onClose){
                        props.onClose();
                    }
                }}></div>
            <div className={Style.content}>
                <div className={Style.top}>
                    <h2 className={Style.title}>{props.title}</h2>
                    <hr className={Style.divider} />
                    <p className={Style.text}>{props.text}</p>
                    {props.children}
                </div>
            </div>
        </div>
    );
};
