import {useEffect, useRef} from 'react';

function useOnClickOutside(handler) {
    const elRef = useRef(null);

    function handleClickOutside(e) {
        if (elRef.current && !elRef.current.contains(e.target)) {
            handler(e);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, []);

    return elRef;
}

export default useOnClickOutside;