import React, { useContext, useEffect, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Icon, ViewContext } from "components/lib";
import Style from "./column.tailwind";
import { useTranslate } from "app/translations";
import { StatusSettings } from "../settings";

const Column = ({ columnId, column, updateTicketStatusMutation, statusTemplate, dragInfo }) => {
    const viewContext = useContext(ViewContext);
    const { _t } = useTranslate();

    const settingsProps = {
        updateTicketStatusMutation, 
        statusTemplate,
    }

    const [draggingFromUnassigned, setDraggingFromUnassigned] = useState(false);
    const isUnassigned = columnId === "unassigned";

    useEffect(() => {
        if (dragInfo.from === "unassigned" && dragInfo.to !== "unassigned") {
            setDraggingFromUnassigned(true);
        }else {
            setDraggingFromUnassigned(false);
        }
    }, [dragInfo]);
    
    // If the column is unassigned and has no statuses, don't render it
    if(column.key === "unassigned"){
        if(column.statuses.length === 0){
            return null;
        }
    }

    const title = _t(`ticket.category.status.${column.key}`);
    return (
        <div
            className={Style.container}
            style={{
                width: isUnassigned && "100%",
            }}
            key={columnId}
        >
            <div className={Style.header}>
                <h3 className={Style.title}>{title}</h3>
                {!isUnassigned && (
                    <div className={Style.action}>
                        <button
                            type="button"
                            className={Style.actionBtn}
                            onClick={() => {
                                viewContext.pannel.show({
                                    title: `${column.label} ${_t('settings.label')}`,
                                    component: <StatusSettings columnId={columnId} title={title} {...settingsProps} />,
                                    styles: {
                                        width: 500,
                                    }
                                });
                            }}>
                            <Icon image={'settings'} size={18} />
                        </button>
                    </div>
                )}
            </div>
            <div>
                <Droppable droppableId={columnId} key={columnId} direction={isUnassigned ? "horizontal" : "vertical"}>
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="rounded-b-2xl"
                                style={{
                                    background: snapshot.isDraggingOver
                                        && "#b6c4f6",
                                    padding: 4,
                                    gap: 10,
                                    width: isUnassigned ? "100%" : 250,
                                    minHeight: !isUnassigned && 280,
                                    display: isUnassigned ? "flex" : "block"
                                }}
                            >
                                {column.statuses.map((item, index) => {
                                    return (
                                        <Draggable
                                            key={item.id.toString()}
                                            draggableId={item.id.toString()}
                                            index={index}
                                        >
                                            {(provided, snapshot) => {
                                                let customWidth = isUnassigned ? "auto" : 230;
                                                if(snapshot.isDragging){
                                                    if(draggingFromUnassigned){
                                                        customWidth = 230;
                                                    }
                                                }
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={Style.statusItem}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            userSelect: "none",
                                                            padding: "8px 13px",
                                                            backgroundColor: snapshot.isDragging
                                                                && "#4eedd0",   
                                                            color: "#000",
                                                            width: customWidth,
                                                            display: isUnassigned ? "inline-block" : "block",
                                                        }}
                                                    >
                                                        <span>{item.name}</span>
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </div>
        </div>
    );
};

export default Column;