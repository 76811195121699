import React from 'react';
import { useTranslate } from 'app/translations';

const Style = {
    container: 'flex flex-wrap w-full',
};

export const Name = ({actionType, historyData}) => {
    const { _t } = useTranslate();
    const { before, after } = historyData;

    const name = (name) => {
        return(
            <span className="text-secondary-600">
                {name}
            </span>
        )
    }
    return (
        <div className={Style.container}>
            <p>
                {_t('ticket.history.event.name.changed').toLowerCase()} {name(before)} {_t('to').toLowerCase()} {name(after)}
            </p>
        </div>
    );
};
