import { Fragment, useState } from 'react';
import { Form, Message} from 'components/lib';
import { useTranslate } from 'app/translations';

export function ContactForm(props){
  const { _t } = useTranslate();
  const [sent, setSent] = useState(false);

  return (
    <Fragment>

      { sent ? 
        <Message  
          title={_t('message.sent')}
          type='success'
          text={_t('message.thanks')}
        />  :

        <Form 
          data={{ 
            name: {
              label: _t('name.yourName'),
              type: 'text',
              required: true,
              errorMessage: _t('name.empty'),
            },
            email: {
              label: _t('email.yourEmail'),
              type: 'email',
              required: true,
              errorMessage: _t('email.empty'),
            },
            message: {
              label: _t('formMessage.title'),
              type: 'textarea',
              required: true,
              errorMessage: _t('formMessage.empty'),
            } 
          }}
          method='POST'
          url='/api/utility/mail'
          callback={ e => setSent(true) }
          buttonText={_t('formMessage.send')}
         />
       }
     </Fragment>
  );
}
