/***
 *
 *   usePlans hook
 *   fetch, format and return the price plans
 *
 **********/

import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useApiContext } from 'app/api';

export function usePlans() {
    const { callApi } = useApiContext();
    const [state, setState] = useState({ data: null, loading: false });
    const plans = useQuery('plans', () => callApi({ url: '/api/account/plans' }));

    useEffect(() => {
        setState({ loading: true });

        // format plans
        if (plans?.data?.data.plans) {
            let formatted = [];
            plans.data.data.plans.map((plan) => {
                let label = `${plan.name} (${plan.currency.symbol}${plan.price}/${plan.interval})`;
                return formatted.push({ value: plan.id, label: label });
            });

            setState({
                data: { list: formatted, active: plans.data.data.active, raw: plans.data.data },
                loading: false,
            });
        }
    }, [plans.data]);

    return state;
}
