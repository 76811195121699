import React from 'react';
import { useTranslate } from 'app/translations';

const Style = {
    container: 'flex flex-wrap w-full',
};

export const Due = ({ historyData }) => {
    const { _t } = useTranslate();
    const { before, after } = historyData;

    const date = (date) => {
        const formattedDate = new Date(date);
        return(
            <span className="text-secondary-600">
                {formattedDate.toLocaleDateString()}
            </span>
        )
    }
    
    const formatContent = () => {
        if(!before){
            return(
                <div>
                    {_t('ticket.history.event.due_date.set').toLowerCase()} {date(after)}
                </div>
            )
        }
        if(!after){
            return(
                <div>
                    {_t('ticket.history.event.due_date.removed').toLowerCase()}  {date(before)}
                </div>
            )
        }
        if(before && after){
            return(
                <div>
                    {_t('ticket.history.event.due_date.changed').toLowerCase()}  {date(before)} {_t('to').toLowerCase()}  {date(after)}
                </div>
            )
        }
    }

    return (
        <div className={Style.container}>
            {formatContent()}
        </div>
    );
};
