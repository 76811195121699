/***
 *
 *   BADGE
 *   Text badge with background color
 *
 *   PROPS
 *   text: string to be displayed
 *   color: blue/red/green/orange (default: purple)
 *
 **********/

import { ClassHelper, Icon } from 'components/lib';
import Style from './badge.tailwind.js';

export function Badge(props) {
    const badgeStyle = ClassHelper(Style, {
        [props.color]: true,
        className: props.className,
    });

    return (
        <span className={badgeStyle} onClick={props.action}>
            {props.text}
            {props.icon && (
                <div className={Style.iconParent} onClick={props.onIconClick}>
                    <Icon
                        image={props.icon}
                        color={props.colorIcon}
                        size={props.sizeIcon || 12}
                        className={Style.icon}
                    />
                </div>
            )}
        </span>
    );
}
