import { Signup } from 'views/auth/signup/account';
import { SignupPlan } from 'views/auth/signup/plan';
import { SignupUser } from 'views/auth/signup/user';
import { SignupProject } from 'views/auth/signup/project';
import { Signin } from 'views/auth/signin';
import { SigninOTP } from 'views/auth/signin/otp';
import { SocialSignin } from 'views/auth/signin/social';
import { ForgotPassword } from 'views/auth/signin/forgotpassword';
import { ResetPassword } from 'views/auth/signin/resetpassword';
import { MagicSignin } from 'views/auth/signin/magicSignin';
import { ImpersonateSignin } from 'views/auth/signin/impersonate';
import { _t } from '../app/translations';

const Routes = [
    {
        path: '/signup',
        view: Signup,
        layout: 'auth',
        title: _t('signup.label'),
    },
    {
        path: '/signup/plan',
        view: SignupPlan,
        layout: 'auth',
        permission: 'owner',
        title: _t('signup.plan'),
        allowNotOnboarded: true,
    },
    {
        path: '/signup/user',
        view: SignupUser,
        layout: 'auth',
        title: _t('signup.label'),
        
    },
    {
        path: '/signup/project',
        view: SignupProject,
        layout: 'auth',
        title: _t('signup.label'),
        
    },
    {
        path: '/signin',
        view: Signin,
        layout: 'auth',
        title: _t('signin.label'),
       
    },
    {
        path: '/signin/otp',
        view: SigninOTP,
        layout: 'auth',
        title: _t('code.label'),
       
    },
    {
        path: '/signin/social',
        view: SocialSignin,
        layout: 'auth',
        title: _t('signin.complete'),
        
    },
    {
        path: '/magic',
        view: MagicSignin,
        layout: 'auth',
        title: _t('signin.label'),
        
    },
    {
        path: '/forgotpassword',
        view: ForgotPassword,
        layout: 'auth',
        title: _t('password.forgot.question'),
       
    },
    {
        path: '/resetpassword',
        view: ResetPassword,
        layout: 'auth',
        title: _t('password.reset.label'),
        
    },
    {
        path: '/signin/impersonate',
        view: ImpersonateSignin,
        layout: 'auth',
        title: _t('Sign in via Mission Control'),
       
    },
];

export default Routes;
