import { useTranslate } from 'app/translations';
import { Button, Icon, Form } from 'components/lib';
import React from 'react';
import { useMutation } from 'react-query';
import { useApiContext } from 'app/api.js';

const SlackItemContent = (props) => {
    const { integration_data, events, id } = props;
    const { _t } = useTranslate();
    const { callApi } = useApiContext();

    const { mutate: sendTestNotification } = useMutation(
        async () => {
            return await callApi({
                url: `/api/integration/${id}/slack/f/sendTestNotification`,
                method: 'post',
                data: {
                    channel: integration_data.channel,
                },
            });
        }
    );

    if (!integration_data.channel) {
        return (
            <div className="bg-red-500/10 p-2 rounded-md border border-red-500 flex items-center space-x-2">
                <Icon image="alert-circle" className="text-red-500" />
                <p>{_t('slack.connectChannel')}</p>
            </div>
        );
    }

    // transform { status: true, create: true, comment: true } to ['status', 'create', 'comment']
    const eventsArray = Object.keys(events).filter((event) => events[event]);

    return (
        <div className="space-y-4">
            <Form
                data={{
                    events: {
                        type: 'checkbox',
                        label: _t('notifications'),
                        default: eventsArray,
                        options: ['status', 'create', 'comment'],
                    },
                }}
                method="PATCH"
                url={`/api/integration/${id}`}
                buttonText={_t('save')}
            />
            <Button
                text={_t('slack.testNotification')}
                outline
                small
                action={sendTestNotification}
            />
        </div>
    );
};

export default SlackItemContent;
