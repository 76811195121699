import React, { useState } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import Column from './column';
  
const onDragEnd = (result, columns, setColumns, updateTicketStatusMutation) => {
    if (!result.destination) return;
    const { source, destination } = result;

    // Clone the original columns array for immutability
    const newColumns = columns.map(column => ({
        ...column,
        statuses: [...column.statuses]
    }));

    // Find indexes in newColumns array
    const sourceIndex = newColumns.findIndex(column => column.key === source.droppableId);
    const destinationIndex = newColumns.findIndex(column => column.key === destination.droppableId);

    if (source.droppableId !== destination.droppableId) {
        const [removed] = newColumns[sourceIndex].statuses.splice(source.index, 1);
        newColumns[destinationIndex].statuses.splice(destination.index, 0, removed);
        
        // Send updated array to database
        updateTicketStatusMutation.mutate({ status_template: newColumns });
    } else {
        const [removed] = newColumns[sourceIndex].statuses.splice(source.index, 1);
        newColumns[sourceIndex].statuses.splice(destination.index, 0, removed);
    }

    // Update state
    setColumns(newColumns);

};

export const Board = ({ statusTemplate, updateTicketStatusMutation }) => {
    const [columns, setColumns] = useState(statusTemplate);
    const [dragInfo, setDragInfo] = useState({});

    const handleDragUpdate = (update) => {
        if (update.destination) {
            const source = update.source.droppableId;
            const columnIdBeingHovered = update.destination.droppableId;
            setDragInfo({ from: source, to: columnIdBeingHovered });
        }
    };
    
    return (
        <div>
            <div style={{ display: "flex", flexWrap: "wrap", height: "100%" }}>
                <DragDropContext 
                    onDragEnd={(result) => onDragEnd(result, columns, setColumns, updateTicketStatusMutation)}
                    onDragUpdate={handleDragUpdate}
                >
                    {columns.map((column, index) => {
                        return (
                            <Column 
                                key={column.key} 
                                columnId={column.key} 
                                column={column} 
                                updateTicketStatusMutation={updateTicketStatusMutation}
                                statusTemplate={statusTemplate} 
                                dragInfo={dragInfo}
                            />
                        );
                    })}
                </DragDropContext>
            </div>
        </div>
    );
};
