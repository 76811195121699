import React from 'react';
import { AccountNav } from 'components/lib';

export const SettingsWrapper = (props) => {
    return (
        <>
            <AccountNav />
            <div className="pt-4">{props.children}</div>
        </>
    );
};
