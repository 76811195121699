/***
 *
 *   PROFILE
 *   Update the organization or close the organization
 *
 **********/

import React, { useContext, useEffect, useRef } from 'react';
import {
    ViewContext,
    Form,
    Card,
    SettingsWrapper,
    Button,
    useNavigate,
    Animate,
    TitleRow,
    Link,
} from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';
import { useApiContext } from 'app/api';
import { useQuery } from 'react-query';
import integrationsConfig from 'config/integrations';
import settings from '../../settings';

const { client_url_main } = settings;

export function Organization(props) {
    const { _t } = useTranslate();
    const navigate = useNavigate();
    const integrationsRef = useRef(null);
    // context
    const { postEvent, callApi } = useApiContext();

    const authContext = useContext(AuthContext);
    const viewContext = useContext(ViewContext);

    const accountId = authContext.user.account_id;

    // fetch
    const user = useQuery('user', () => callApi({ url: '/api/user' }));

    const account = user.data?.data.accounts.find((account) => account.id === accountId);

    const integrations = useQuery('integration', () => callApi({ url: '/api/integration' }));

    function closeAccount() {
        viewContext.modal.show(
            {
                title: _t('organization.close.title'),
                form: {},
                buttonText: _t('organization.close.label'),
                url: authContext.permission.owner ? '/api/account' : '/api/user',
                method: 'DELETE',
                destructive: true,
                text: _t('organization.delete.confirm'),
            },
            () => {
                postEvent('closed_account');
                localStorage.clear();
                navigate('/signup');
            },
        );
    }

    function createIntegration() {
        viewContext.modal.show(
            {
                title: _t('integration.add'),
                form: {
                    name: {
                        label: _t('integration.name'),
                        type: 'text',
                        required: true,
                    },
                    service: {
                        label: 'Services',
                        type: 'select',
                        required: true,
                        options: [
                            { value: 'clickup', label: 'Clickup' },
                            {
                                value: 'slack',
                                label: 'Slack',
                            },
                        ],
                        default: 'clickup',
                    },
                },
                buttonText: _t('integration.connect'),
                url: '/api/integration/connect',
                method: 'post',
                headers: {
                    'Redirect-Url': window.location.href,
                },
            },
            (data, res) => {
                integrations.refetch();
                if (!res.redirect) return;
                window.location.href = res.redirect;
            },
        );
    }

    async function editIntegration(id) {
        const integration = integrations.data.data.find((x) => x.id === id);
        const integrationForm = await integrationsConfig[integration.service_name].form(
            integration,
        );

        viewContext.modal.show(
            {
                title: _t('integration.edit'),
                form: {
                    name: {
                        label: _t('integration.name'),
                        type: 'text',
                        required: true,
                        value: integration.name,
                    },
                    ...integrationForm,
                },
                buttonText: _t('save'),
                url: `/api/integration/${id}`,
                method: 'patch',
            },
            () => {
                integrations.refetch();
            },
        );
    }

    function deleteIntegration(id) {
        viewContext.modal.show(
            {
                title: _t('integration.delete.title'),
                form: {},
                buttonText: _t('delete'),
                url: `/api/integration/${id}`,
                method: 'delete',
                destructive: true,
                text: _t('integration.delete.confirm'),
            },
            () => {
                integrations.refetch();
            },
        );
    }

    useEffect(() => {
        if (
            window.location.hash === '#integrations' &&
            integrations.isFetched
        ) {
            setTimeout(() => {
            integrationsRef.current.scrollIntoView({ behavior: 'smooth' , block: 'start'});
            } , 500);
        }
    }, [integrations]);

    return (
        <SettingsWrapper>
            <Animate>
                <div className="flex flex-col">
                    <Card title={_t('organization.edit.text')} loading={user.isLoading} centerFit>
                        {user?.data && (
                            <Form
                                buttonText={_t('save')}
                                url="/api/account"
                                method="PUT"
                                data={{
                                    logo: {
                                        label: 'Logo',
                                        type: 'logo',
                                        name: 'logo',
                                        required: false,
                                        placeholder: account.file_path,
                                    },
                                    domain: {
                                        label: _t('domain.label'),
                                        type: 'domain',
                                        required: true,
                                        value: account.domain,
                                        placeholder: `Ex: my-company-name`,
                                        domain: `.${client_url_main}`,
                                    },
                                    name: {
                                        type: 'text',
                                        label: _t('organization.name'),
                                        value: account.name,
                                    },
                                }}
                                callback={(res) => {
                                    // update the organization name
                                    if (authContext.user?.accounts?.length > 0) {
                                        const accounts = [...authContext.user.accounts];

                                        const userAccount =
                                            accounts[
                                                accounts.findIndex(
                                                    (x) => x.id === authContext.user.account_id,
                                                )
                                            ];

                                        userAccount.name = res.data.name;
                                        userAccount.domain = res.data.domain;
                                        userAccount.file_path = res.data.file_path;

                                        authContext.update({ accounts: [userAccount] });

                                        // Make sure the domain is updated before switching
                                        setTimeout(() => {
                                            if (res.data.domain !== account.domain) {
                                                authContext.switchDomain(res.data.domain);
                                            }
                                        }, 1);
                                    }
                                }}
                            />
                        )}

                        <div className="flex flex-col justify-center items-center space-y-6">
                            <br />
                            <Link
                                className="text-gray-500 underline"
                                url={'/'}
                                text={_t('organization.viewPage')}
                            />
                            <Button
                                textOnly
                                className="text-red-500"
                                action={closeAccount}
                                text={_t('organization.close.title')}
                            />
                        </div>
                    </Card>
                    <div ref={integrationsRef}>
                        {/* Integrations */}
                        <Card loading={integrations.isLoading} id="test">
                            <TitleRow title={_t('integration.title.multiple')}>
                                <Button
                                    action={() => createIntegration()}
                                    text={_t('integration.add')}
                                />
                            </TitleRow>

                            {integrations?.data?.data.length > 0 ? (
                                integrations.data.data.map((integration, i) => {
                                    return (
                                        <div key={i} className={Style.integrationCard}>
                                            <div className={Style.integrationInfo}>
                                                <img
                                                    src={`/assets/${integration.service_name}-logo.png`}
                                                    className={Style.integrationLogo}
                                                    alt={integration.service_name}
                                                />
                                                <p className="text-lg mt-2">{integration.name}</p>
                                            </div>
                                            {integrationsConfig[
                                                integration.service_name
                                            ].item.content(integration)}
                                            <div className={Style.integrationButtons}>
                                                <Button
                                                    small
                                                    color={'blue'}
                                                    outline
                                                    className="mr-2 px-1 py-1 text-xs !text-white"
                                                    action={() => editIntegration(integration.id)}
                                                    text={_t('edit')}
                                                />
                                                <Button
                                                    action={() => deleteIntegration(integration.id)}
                                                    color="red"
                                                    small
                                                    outline
                                                    className="px-1 py-1 text-xs"
                                                    text={_t('delete')}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <p>{_t('integration.empty')}</p>
                            )}
                        </Card>
                    </div>
                </div>
            </Animate>
        </SettingsWrapper>
    );
}

const Style = {
    integrationCard:
        'flex items-center justify-between border border-gray-200 rounded-2xl p-4 shadow-md my-4',
    integrationInfo: '',
    integrationLogo: 'w-24 mr-4',
    integrationButtons: 'flex items-center justify-center',
};
