import React from 'react';
import { useTranslate } from 'app/translations';

const Style = {
    container: 'flex flex-wrap w-full',
};

export const Section = ({ historyData }) => {
    const { _t } = useTranslate();
    const { before, after } = historyData;

    const name = (name) => {
        return(
            <span className="text-secondary-600">
                {name}
            </span>
        )
    }
    const changedProject = before.project_id !== after.project_id;
    return (
        <div className={Style.container}>
            <p>
                {_t('ticket.history.event.section.changed').toLowerCase()} {name(before.name)} {_t('to').toLowerCase()} {name(after.name)} {changedProject && `${_t('ticket.history.event.section.inProject').toLowerCase()} ${after.project.name}`}
            </p>
        </div>
    );
};
