import { useState, useEffect } from 'react';
import useOnClickOutside from './onClickOutside';

// Attach a div relative to an element
// PROPS
// - ref: the ref of the element to which he is attached
// - position: 'top', 'bottom', 'left', 'right'
// - offset: number: the offset of the attached element
// - children: the children of the attached element

export function useAttach(props) {
    const { ref, position = 'top', offset = 10, children } = props;
    const elRef = useOnClickOutside(handleClickOutside);
    const [positionStyle, setPositionStyle] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();

            let style = {
                position: 'absolute',
                opacity: isOpen ? 1 : 0,
                pointerEvents: isOpen ? 'all' : 'none',
                zIndex: 100,
            };

            switch (position) {
                case 'top':
                    style = {
                        ...style,
                        bottom: `${rect.height + offset}px`,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    };
                    break;
                case 'bottom':
                    style = {
                        ...style,
                        top: `${rect.height + offset}px`,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    };

                    break;
                case 'left':
                    style = {
                        ...style,
                        top: '50%',
                        right: `${rect.width + offset}px`,
                        transform: 'translateY(-50%)',
                    };
                    break;
                case 'right':
                    style = {
                        ...style,
                        top: '50%',
                        left: `${rect.width + offset}px`,
                        transform: 'translateY(-50%)',
                    };
                    break;
                default:
                    break;
            }

            setPositionStyle(style);
        }
    }, [ref.current, isOpen]);

    function handleClickOutside(e) {
        const firstChild = ref.current.children[0];

        // prevent closing when clicking on the first child of ref(button)
        if (firstChild.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    }

    return {
        toggle,
        isOpen,
        Component: isOpen
            ? () => {
                  return (
                      <div
                          ref={elRef}
                          style={positionStyle}
                          className={`transition-all duration-300 ease-in-out`}>
                          {children}
                      </div>
                  );
              }
            : () => null,
    };
}
