export function objectsToArray(data){
    const arrayOfObject = [];

    Object.values(data).map((item) => {
      typeof item === "object" && arrayOfObject.push(item);
    });

    return arrayOfObject;
}

export function isId(part) {
  // UUID 4 regex
  const idRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return idRegex.test(part);
}

export function firstLetterToUpperCase(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export function formatFileName(fileName, maxCharacters = 15) {
  const extension = fileName.split('.').pop();
  
  if(fileName.length > maxCharacters) {
      // Subtract extension length and 3 (for the ellipsis) to get the truncated part length
      const truncatedPart = fileName.slice(0, maxCharacters - extension.length - 3); 
      return `${truncatedPart}..${extension}`;
  } else {
      return fileName;
  }
}
