/***
 *
 *   MAGIC SIGN IN
 *   Confirms magic token and redirects to dashboard if successful
 *
 **********/

import React, { useState, useContext, useEffect, useRef } from 'react';
import { Animate, Row, Message, useNavigate } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';
import { useApiContext } from 'app/api';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getUserCache } from 'utilities/cache';

export function MagicSignin() {
    const { _t } = useTranslate();
    const user = getUserCache(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const project = searchParams.get('project');
    const account = searchParams.get('account');
    const redirect = searchParams.get('redirect');
    const context = useRef(useContext(AuthContext));
    const { callApi } = useApiContext();

    if (user) {
        navigate(redirect || '/app/tickets');
    }

    const [message, setMessage] = useState({
        type: 'success',
        title: _t('magic.use'),
        text: _t('signin.loading'),
    });

    const { mutate: addUserToAccount } = useMutation(async (id) => {
        callApi({
            url: `/api/user/${id}/account/${account}`,
            method: 'put',
            auth: false,
        });
    });

    const { mutateAsync: addUserToProject } = useMutation(async (id) => {
        callApi({
            url: `/api/user/${id}/project/${project}`,
            method: 'put',
            auth: false,
        });
    });

    const { mutateAsync: verifyToken } = useMutation(
        async (token) => {
            return await callApi({
                url: '/api/auth/magic/verify',
                method: 'post',
                data: { token: token },
                auth: false,
            });
        },
        {
            onSuccess: async (res) => {
                if (res.status === 200) {
                    if (account) {
                        await addUserToAccount(res.data.user_id, account);
                    }
                    if (project) {
                        await addUserToProject(res.data.user_id, project);
                    }
                    res['2fa_required']
                        ? navigate(`/signin/otp?token=${res.data.token}`)
                        : context.current.signin(res, account && account, redirect);
                } else {
                    invalidLink();
                }
            },
            onError: (err) => {
                invalidLink();
            },
        },
    );

    useEffect(() => {
        if (token) {
            // check token exists
            verifyToken(token);
        } else {
            invalidLink();
        }
    }, [token, context, navigate]);

    function invalidLink() {
        setMessage({
            type: 'error',
            title: _t('magic.invalid'),
            text: 'Please generate a new link and try again',
            buttonLink: '/signin',
            buttonText: _t('signin.back'),
        });
    }

    return (
        <Animate>
            <Row>
                <div style={{ width: '28em', margin: '0 auto' }}>
                    <Message
                        type={message.type}
                        title={message.title}
                        text={message.text}
                        buttonText={message.buttonText}
                        buttonLink={message.buttonLink}
                    />
                </div>
            </Row>
        </Animate>
    );
}
