import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useApiContext } from 'app/api';
import { Form, useProjectContext } from 'components/lib';
import { useTranslate } from 'app/translations';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const ClickupIntegrationConnector = (props) => {
    const {
        data,
        url,
        method,
        injectedData,
        navigateToCategory,
        connectTo = 'list',
        onSuccess,
        progressive = true,
    } = props;

    const service = 'clickup';
    const connectionOrder = ['integration', 'team', 'space', 'list'];
    const connectionLevel = connectionOrder.indexOf(connectTo);
    const { callApi } = useApiContext();
    const { _t } = useTranslate();
    const { selectedProject } = useProjectContext();
    const navigate = useNavigate();
    const { id } = useParams();

    const [formValues, setFormValues] = useState({
        integration: data?.integration,
        team: data?.team,
        space: data?.space,
        list: data?.list,
    });

    const [integrationData, setIntegrationData] = useState({
        spaces: [],
        lists: [],
    });

    const { spaces, lists } = integrationData;

    const onFormChange = (name, value) => {
        setFormValues({ ...formValues, [name]: value });
    };

    // get initial data for clickup connection
    const initialConnectionData = useQuery(
        'initialConnectionData',
        () => {
            return callApi({ url: `/api/integration/${service}/f/getTicketCategoryInit` });
        },
        {
            onSuccess: (res) => {
                formValues.integration &&
                    getSpaces({
                        teamId: formValues.team,
                        integration: formValues.integration,
                    });
            },
        },
    );

    const integrations = initialConnectionData.data?.data?.integrations;
    const hasMultipleIntegrations = integrations?.length > 1;
    let integrationsTeams = [];

    if (integrations) {
        integrationsTeams = hasMultipleIntegrations
            ? integrations?.map((integration) => {
                  return {
                      label: integration.name,
                      value: integration.id,
                      options: integration?.teams?.map((team) => ({
                          label: team.name,
                          value: team.id,
                      })),
                  };
              })
            : integrations?.[0]?.teams.map((team) => ({
                  label: team.name,
                  value: team.id,
              }));
    }

    const { mutate: getSpaces, isLoading: spaceIsLoading } = useMutation(
        'integrationSpaces',
        ({ teamId, integration }) =>
            callApi({
                url: `/api/integration/${integration}/${service}/f/getSpaces?teamId=${teamId}`,
            }),
        {
            onSuccess: (res) => {
                setIntegrationData((prev) => ({
                    ...prev,
                    spaces: res.data,
                }));
                formValues.space && getLists(formValues.space);
            },
        },
    );
    const { mutate: getLists, isLoading: listIsLoading } = useMutation(
        'integrationLists',
        (value) =>
            callApi({
                url: `/api/integration/${formValues.integration}/${service}/f/getLists?spaceId=${value}`,
            }),
        {
            onSuccess: (res) => {
                setIntegrationData((prev) => ({
                    ...prev,
                    lists: res.data,
                }));
            },
        },
    );
    let formData = {
        team: {
            type: hasMultipleIntegrations ? 'selectGroup' : 'select',
            label: 'Team',
            name: 'team',
            options: integrationsTeams,
            value: formValues?.team,
            required: true,
            onChange: (value) => {
                // get id of chosen integration
                const integrationWithChosenTeam = findChosenIntegration(integrations, value);

                setFormValues({
                    space: null,
                    list: null,
                    team: value,
                    integration: integrationWithChosenTeam.id,
                });

                getSpaces({
                    integration: integrationWithChosenTeam.id,
                    teamId: value,
                });
            },
        },
    };

    const canShowSpace = progressive ? spaces?.length : true;

    const canShowList = progressive ? lists?.length : true;

    if (canShowSpace && canConnectTo('space')) {
        formData.space = {
            type: 'select',
            label: 'Space',
            required: true,
            options: spaces.map((space) => {
                return {
                    label: space.name,
                    value: space.id,
                };
            }),
            value: formValues?.space,
            onChange: (value) => {
                setFormValues({
                    ...formValues,
                    list: null,
                    space: value,
                });
                getLists(value);
            },
        };
    }

    if (canShowList && canConnectTo('list')) {
        formData.list = {
            type: 'select',
            label: 'List',
            required: true,
            options: lists.map((list) => {
                return {
                    label: list.name,
                    value: list.id,
                };
            }),
            value: formValues?.list,
            onChange: (value) => {
                const name = lists.find((list) => list.id === value).name;
                
                setFormValues({
                    ...formValues,
                    list: value,
                    name: name,
                });
            },
        };
    }

    function canConnectTo(connectTo) {
        return connectionLevel >= connectionOrder.indexOf(connectTo);
    }

    function findChosenIntegration(integrations, teamId) {
        return integrations.find((integration) => {
            return integration.teams.find((team) => team.id === teamId);
        });
    }

    // only show loading if we are in progressive mode
    const isLoading = (initialConnectionData.isLoading || listIsLoading || spaceIsLoading) && progressive;

    return (
        <Form
            data={formData}
            injectedData={{
                name: formValues?.name,
                project_id: selectedProject?.id,
                integration_data: {
                    integration: formValues?.integration,
                    team: formValues?.team,
                    space: formValues?.space,
                    list: formValues?.list,
                    service: service,
                },
                ...injectedData,
            }}
            updateOnChange
            hideSubmit={!formValues[connectTo]}
            onChange={(field) => {
                onFormChange(field.input, field.value);
            }}
            callback={(res) => {
                if (res.status === 200) {
                    navigateToCategory && navigate(`/app/tickets/category/${res.data.id}`);

                    onSuccess && onSuccess(res);
                }
            }}
            buttonText={_t('save')}
            isLoading={isLoading}
            method={method}
            url={`${url}${method === 'POST' ? '' : `/${id}`}`}
        />
    );
};

export default ClickupIntegrationConnector;
