/***
*
*   ACTIONS
*
*   PROPS for a single action
*   
*   title: string for the button title, shown on hover
*
**********/

import { Button } from 'components/lib';
import { useTranslate } from 'app/translations';
import Style from './table.tailwind.js';

export default function TableActions(props) {
    const row = props.row;
    const { _t } = useTranslate();

    return (
        <td key={props.index} className={Style.actions}>
            {row.actions?.custom?.map((action, i) => {
                if (action.conditions && action.conditions.length > 0) {
                    const isValid = action.conditions.every((condition) => {
                        if(Array.isArray(condition.value)) {
                            return condition.value.includes(row[condition.col]);
                        }
                        
                        return row[condition.col] === condition.value;
                    });

                    if (!isValid) {
                        return null;
                    }

                    return (
                        <Button
                            title={action.title}
                            key={i}
                            icon={action.icon}
                            action={() => action.action(row)}
                            className={Style.actionButton}
                        />
                    );
                }

                return (
                    <Button
                        title={action.title}
                        key={i}
                        icon={action.icon}
                        action={() => action.action(row)}
                        className={Style.actionButton}
                    />
                );
            })}

            {row.actions.add && (
                <Button
                    title={_t("table.actions.add")}
                    icon="plus"
                    action={() => row.actions.add(row)}
                    className={Style.actionButton}
                />
            )}

            {row.actions.edit && (
                <Button
                    title={_t("table.actions.edit")}
                    icon="edit"
                    action={() => row.actions.edit(row, (res) => props.callback.edit(res, row))}
                    className={Style.actionButton}
                />
            )}

            {row.actions.download && (
                <Button
                    title={_t("table.actions.download")} 
                    icon="download" 
                    url={row.actions.download} 
                    className={Style.actionButton} />
            )}

            {row.actions.view && (
                <Button
                    title={_t("table.actions.view")}
                    icon="eye"
                    url={`${row.actions.view.url}/${row[row.actions.view.col]}`}
                    className={Style.actionButton}
                />
            )}

            {row.actions.email && (
                <Button
                    title={_t("table.actions.mail")}
                    icon="mail"
                    action={() => (window.location = `mailto:${row.email}`)}
                    className={Style.actionButton}
                />
            )}

            {row.actions.invite && (
                <Button
                    title={_t("table.actions.invite")}
                    icon="mail"
                    action={(e) => row.actions.invite(row)}
                    className={Style.actionButton}
                />
            )}

            {row.actions.delete && (
                <Button
                    title={_t("table.actions.delete")}
                    icon="trash"
                    action={() => row.actions.delete(row, (res) => props.callback.delete(res, row))}
                    className={Style.actionButton}
                />
            )}
        </td>
    );
}
