import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useApiContext } from 'app/api';
import { useQueryClient } from 'react-query';
import {
    Animate,
    Button,
    Card,
    TitleRow,
    Table,
    ViewContext,
    useProjectContext,
    useNavigate,
} from 'components/lib';
import { useTranslate } from 'app/translations';

const ProjectContacts = (props) => {
    const { projectUsers, users } = props;
    const queryClient = useQueryClient();
    // Hooks
    const { callApi, postEvent } = useApiContext();
    const navigate = useNavigate();
    const { _t } = useTranslate();
    const params = useParams();

    // Context
    const context = useContext(ViewContext);
    const { selectedProject, isAllProjectSelected, projects } = useProjectContext();

    // Variables / States
    const id = selectedProject?.id || params?.id;
    const { invites, availableContacts } = users?.data?.data;
    const [contacts, setContacts] = useState(projectUsers?.data?.contacts);
    const usersFetched = projectUsers.data?.contacts && contacts;

    // contact invites
    const contactInvites = invites.filter((invite) => invite.permission === 'contact');

    // Lifecycle
    useEffect(() => {
        if (!users.data || !projectUsers.data) return;
        const contacts = processData(projectUsers.data?.contacts, 'registered');
        const invitations = processData(contactInvites || [], 'invited');
        const availableContactsData = processData(availableContacts, 'available');
        setContacts([...contacts, ...invitations, ...availableContactsData]);
    }, [projectUsers, invites]);

    const processData = (data, status) =>
        data.map((item) => ({
            id: item?.user_id || item?.id,
            project_id: item?.project_id,
            first_name: item?.first_name || _t('undefined'),
            last_name: item?.last_name || _t('undefined'),
            permision: item?.permission,
            email: item?.email,
            project_role: item?.role ? _t(`permissions.${item?.role}`) : _t('undefined'),
            status: _t(`table.badge.${status}`),
            active: status === 'registered',
            invited: status === 'invited',
        }));

    const { mutate: resendInvite } = useMutation(
        async (data) => {
            await callApi({
                url: '/api/invite?target=contact',
                method: 'post',
                data: { email: data.email },
            });
        },
        {
            onSuccess: (data, variables) => {
                context.notification.show(
                    _t('invite.reSendTo', {
                        email: variables.email,
                    }),
                    'success',
                    true,
                );
            },
            onError: (err) => {
                context.handleError(err);
            },
        },
    );

    const { mutate: addProjectUser } = useMutation(
        (data) =>
            callApi({
                url: `/api/project/${id}/user`,
                method: 'POST',
                data,
            }),
        {
            onSuccess: () => {
                projectUsers.refetch();
            },
        },
    );

    // Actions function
    function invite() {
        context.modal.show(
            {
                title: _t('contact.add.one'),
                form: {
                    email: {
                        label: _t('email.label'),
                        type: 'text',
                        required: true,
                    },
                    role: {
                        label: _t('role.label'),
                        type: 'select',
                        default: 'contact',
                        options: [
                            { value: 'admin', label: 'Admin' },
                            { value: 'contact', label: 'Contact' },
                        ],
                    },
                    ...(isAllProjectSelected && {
                        project_id: {
                            label: _t('project.label.one'),
                            type: 'select',
                            default: id,
                            options: projects.map((project) => ({
                                value: project.id,
                                label: project.name,
                            })),
                        },
                    }),
                },
                buttonText: _t('invite.send'),
                text: _t('invite.sendInfo'),
                url: `/api/invite/${id}?target=contact`,
                method: 'POST',
            },
            () => {
                postEvent('invited_user');
                props.users.refetch();
            },
        );
    }

    function deleteInvite(data, callback) {
        context.modal.show(
            {
                title: _t('invite.delete.label'),
                form: {},
                buttonText: _t('invite.delete.label'),
                text: _t('invite.delete.confirm', { email: data.email }),
                url: `/api/invite/${data.id}?target=contact`,
                method: 'DELETE',
                destructive: true,
            },
            () => {
                context.notification.show(`${_t('invite.wasDeleted')}`, 'success', true);

                props.users.refetch();

                callback();
            },
        );
    }

    function edit(user) {
        navigate(`/app/contacts/${user.id}`, {
            state: {
                title: user.first_name,
            },
        });
    }

    function addUser(data) {
        addProjectUser({
            user_id: data.id,
            project_id: id,
        });
    }

    function removeFromProject(user) {
        // remove user from project
        context.modal.show(
            {
                title: _t('project.contact.remove.label'),
                form: {},
                buttonText: _t('confirm'),
                text: _t('project.contact.remove.text'),
                url: `/api/project/${id}/user`,
                method: 'DELETE',
                destructive: true,
                injectedData: {
                    user_id: user.id,
                    project_id: id,
                },
            },
            (res) => {
                queryClient.invalidateQueries('projects');
                if (res.status === 200) {
                    projectUsers.refetch();
                }
            },
        );
    }

    if (users.isLoading || projectUsers.isLoading) return null;

    return (
        <Animate>
            <Card>
                <TitleRow title={_t('contact.manage.label.multiple')}>
                    <div className="flex gap-px">
                        <Button small text={_t('contact.invite.label.one')} action={invite} />
                    </div>
                </TitleRow>

                {usersFetched && (
                    <Table
                        search
                        className="restrict-width"
                        data={contacts}
                        loading={projectUsers.isLoading}
                        show={
                            isAllProjectSelected
                                ? ['email', 'first_name', 'last_name']
                                : ['email', 'first_name', 'last_name', 'status', 'project_role']
                        }
                        actions={{
                            custom: [
                                ...(!isAllProjectSelected || params?.id
                                    ? [
                                          {
                                              icon: 'user-minus',
                                              action: removeFromProject,
                                              title: _t('project.contact.remove.label'),
                                              conditions: [
                                                  { col: 'active', value: true },
                                                  {
                                                      col: 'role',
                                                      value: [
                                                          _t(`permissions.admin`),
                                                          _t('permissions.contact'),
                                                      ],
                                                  },
                                              ],
                                          },
                                      ]
                                    : []),
                                {
                                    icon: 'edit',
                                    title: _t('edit'),
                                    action: edit,
                                    conditions: [{ col: 'invited', value: false }],
                                },
                                ...(!isAllProjectSelected || params?.id
                                    ? [
                                          {
                                              icon: 'user-minus',
                                              action: removeFromProject,
                                              title: _t('project.contact.remove.label'),
                                              conditions: [{ col: 'active', value: true }],
                                          },
                                      ]
                                    : []),
                                {
                                    icon: 'mail',
                                    title: _t('project.contact.invite.resend'),
                                    action: resendInvite,
                                    conditions: [
                                        { col: 'active', value: false },
                                        { col: 'invited', value: true },
                                    ],
                                },
                                {
                                    icon: 'trash',
                                    title: _t('project.contact.invite.delete'),
                                    action: deleteInvite,
                                    conditions: [
                                        { col: 'active', value: false },
                                        { col: 'invited', value: true },
                                    ],
                                },
                                ...(!isAllProjectSelected || params?.id
                                    ? [
                                          {
                                              icon: 'user-plus',
                                              title: _t('project.contact.add'),
                                              action: addUser,
                                              conditions: [
                                                  { col: 'active', value: false },
                                                  { col: 'invited', value: false },
                                              ],
                                          },
                                      ]
                                    : []),
                            ],
                        }}
                        badge={{
                            col: 'status',
                            color: 'blue',
                            condition: [
                                { value: _t('table.badge.registered'), color: 'green' },
                                { value: _t('table.badge.invited'), color: 'blue' },
                            ],
                        }}
                    />
                )}
            </Card>
        </Animate>
    );
};

export default ProjectContacts;
