import React, { Fragment, useEffect, useContext } from 'react';
import {
    Card,
    Grid,
    AccountNav,
    usePermissions,
    TicketItem,
    ViewContext,
    Table,
    Button,
} from 'components/lib';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useApiContext } from 'app/api';
import { UserForm } from 'components/form/template/user';
import { _t } from 'app/translations';
import Style from './edit.tailwind';
import { useBreadcrumbsContext } from 'context/BreadcrumbsContext';
import { useProjectContext } from 'components/lib';

const EditUser = () => {
    // Hooks
    const { id, userId } = useParams();
    const { callApi } = useApiContext();
    const permissions = usePermissions();
    const { pushParams } = useBreadcrumbsContext();
    const { selectedProject, isAllProjectSelected } = useProjectContext();
    const navigate = useNavigate();
    const context = useContext(ViewContext);

    const {
        data: user,
        error,
        isLoading,
        refetch,
    } = useQuery(userId && ['user', userId], () => callApi({ url: `/api/user/${userId}` }));

    const { data: projects, refetch: refetchProjects } = useQuery('userProjects', () =>
        callApi({ url: `/api/projects/with-users` }),
    );

    const url =
        `/api/tickets?user_id=${userId}&limit=10` +
        (isAllProjectSelected ? '' : `&project_id=${selectedProject?.id}`);
    const { data: userTickets } = useQuery(
        ['userTickets', userId, selectedProject],
        () =>
            callApi({
                url,
            }),
        {
            enabled: !!selectedProject?.id || isAllProjectSelected,
        },
    );

    const { mutate: addProjectUser } = useMutation(
        (data) =>
            callApi({
                url: `/api/project/${data.project_id}/user`,
                method: 'POST',
                data,
            }),
        {
            onSuccess: () => {
                refetchProjects();
            },
        },
    );

    const userType = user?.data.is_contact ? 'contact' : 'user';

    useEffect(() => {
        if (user && user.data.first_name) {
            pushParams({
                userId: user.data.first_name,
            });
        }
    }, [user]);

    if (isLoading) return null;
    if (error) return <p>Error: {error.message}</p>;
    if (!user || !user.data.first_name) return null;

    function removeFromProject(project) {
        context.modal.show(
            {
                title: _t('project.user.remove.label'),
                form: {},
                buttonText: _t('confirm'),
                text: _t('project.user.remove.text'),
                url: `/api/project/${id}/user`,
                method: 'DELETE',
                destructive: true,
                injectedData: {
                    user_id: userId,
                    project_id: project.id,
                },
            },
            (res) => {
                if (res.status === 200) {
                    refetchProjects();
                }
            },
        );
    }

    function addToProject(project) {
        addProjectUser({
            user_id: userId,
            project_id: project.id,
        });
    }

    function deleteUser() {
        context.modal.show(
            {
                title: _t(`${userType}.delete.label`),
                form: {},
                buttonText: _t(`${userType}.delete.label`),
                text: _t(`${userType}.delete.confirm`, {
                    name: `${user.data.first_name} ${user.data.last_name}`,
                }),
                url: `/api/user/${userId}/organization`,
                method: 'DELETE',
                destructive: true,
            },
            (res) => {
                context.notification.show(
                    user?.data?.first_name + ' ' + _t('message.wasDeleted'),
                    'success',
                    true,
                );

                if (res.status === 200) {
                    userType === 'user'
                        ? navigate(`app/settings/users`)
                        : navigate(`/app/contacts`);
                }
            },
        );
    }

    function editProjectUser(data) {
        context.modal.show(
            {
                title: _t(`${userType}.update.label`),
                form: {
                    role: {
                        label: _t('project.role'),
                        type: 'select',
                        value: data.role.toLowerCase(),
                        options: [
                            { value: 'admin', label: _t('permissions.admin') },
                            { value: 'contact', label: _t('contact.label.one') },
                        ],
                    },
                },
                buttonText: _t('update'),
                url: `/api/project/${data.id}/user`,
                method: 'PUT',
                injectedData: {
                    user_id: userId,
                    project_id: data.id,
                },
            },
            () => {
                refetchProjects();
            },
        );
    }

    const formattedProjects = projects?.data?.map((project) => {
        const role = project.users.find((user) => user.user_id === userId)?.role;

        return {
            ...project,
            isInProject: project.users.some((user) => user.user_id === userId),
            role: role ? _t(`project.roles.${role}`) : _t('undefined'),
        };
    });

    return (
        <Fragment>
            {userType === 'user' && <AccountNav />}

            {!isLoading && !error && (
                <Card>
                    <div className="flex justify-between mb-4">
                        <span className={Style.subtitle}>{_t(`${userType}.update.label`)}</span>
                        {user.data.permission !== 'owner' && (
                            <Button
                                text={`${_t('delete')} ${_t(`permissions.${userType}`)}`}
                                action={deleteUser}
                                color="red"
                                small
                            />
                        )}
                    </div>
                    <Grid col="2" className="flex">
                        <div className="w-1/2 p-5 border-x-2 border-l-0">
                            <UserForm
                                userType={userType}
                                user={user}
                                refetch={refetch}
                                permissions={permissions}
                            />
                        </div>

                        <div className="w-1/2 p-5">
                            <h2 className={Style.subtitle + ' mb-3'}>{_t('ticket.latest')}</h2>
                            <div className=" h-full max-h-[500px] overflow-auto">
                                {userTickets?.data?.map((ticket, index) => {
                                    return (
                                        <TicketItem
                                            key={index}
                                            data={ticket}
                                            onItemClick={() => {
                                                navigate(`/app/tickets?id=${ticket.number}`);
                                            }}
                                        />
                                    );
                                })}
                                {userTickets?.data?.length === 0 && (
                                    <p className="text-center">{_t('ticket.empty')}</p>
                                )}
                            </div>
                        </div>
                    </Grid>
                </Card>
            )}

            <Card title={_t(`project.label.multiple`)}>
                <Table
                    search
                    className="restrict-width"
                    loading={projects?.isLoading}
                    data={formattedProjects}
                    show={['name', 'role']}
                    actions={{
                        custom: [
                            ...(user.data.permission !== 'owner'
                                ? [
                                      {
                                          title: _t(`project.${userType}.edit.label`),
                                          icon: 'edit',
                                          action: (data) => {
                                              editProjectUser(data);
                                          },
                                          conditions: [
                                              { col: 'isInProject', value: true },
                                              {
                                                  col: 'role',
                                                  value: [
                                                      _t(`project.roles.contact`),
                                                      _t(`project.roles.admin`),
                                                  ],
                                              },
                                          ],
                                      },
                                      {
                                          title: _t(`project.${userType}.remove.label`),
                                          icon: 'user-minus',
                                          action: (data) => {
                                              removeFromProject(data);
                                          },
                                          conditions: [{ col: 'isInProject', value: true }],
                                      },
                                      {
                                          title: _t(`project.${userType}.add`),
                                          icon: 'user-plus',
                                          action: (data) => {
                                              addToProject(data);
                                          },
                                          conditions: [{ col: 'isInProject', value: false }],
                                      },
                                  ]
                                : []),
                        ],
                    }}
                />
            </Card>
        </Fragment>
    );
};

export default EditUser;
