import React, { useContext } from 'react';
import { Animate, Row, Card, Form, useNavigate, useLocation } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';

export function SigninOTP(props){
  const { _t } = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AuthContext);

  const token = location?.search?.substring(7);
  if (!token) navigate('/signin');

  return (
    <Animate type='pop'>
      <Row title={_t('code.enter')} desc={_t('code.enterDesc')}>

        <Card restrictWidth center>
          <Form 
            method='post'
            url='/api/auth/otp'
            data={{
              code: {
                type: 'text',
                label: _t('code.label'),
                required: true
              },
              jwt: {
                type: 'hidden',
                value: token,
              } 
            }}
            buttonText={_t('signin.complete')}
            callback={ context.signin }
          />

          <div className='mt-4'>
            {_t('code.lostAccess')}
          </div>

        </Card>

      </Row>
    </Animate>
  )
}