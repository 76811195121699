import React from 'react';

const Style = {
    container: 'flex flex-wrap w-full',
};

export const Text = (comment) => {
    const attributes = comment.attributes;

    let classes = "";

    if (attributes) {
        if (attributes.underline) {
            classes += ' underline';
        }
        if (attributes.italic) {
            classes += ' italic';
        }
        if (attributes['color-class']) {
            classes += ` text-${attributes['color-class']}-500`;
        }
        if (attributes['background-class']) {
            classes += ` bg-${attributes['background-class']}-500`;
        }
        if (attributes.bold) {
            classes += ' font-bold';
        }
    }

    return (
        <div className={Style.container}>
            <span className={classes.trim()}>
                {comment.text}
            </span>
        </div>
    );
};
