/***
 *
 *   FILE UPLOADER
 *   Drag & drop file upload component
 *   Can upload multiple files
 *   Includes fallback for older browser
 *
 *   PROPS
 *   name: input name
 *   max: max number of files
 *   maxFileSize: limit size of file in mb§
 *   accept: array of filetypes to accept, eg. ['jpg', 'gif']
 *
 **********/

import { useEffect, useState, useRef } from 'react';
import { Icon, ClassHelper, Button } from 'components/lib';
import { Label } from '../label/label';
import { Error } from '../error/error';
import Style from './logo.tailwind.js';
import styles from './logo.module.scss';
import { useTranslate } from 'app/translations';

export function LogoFileInput(props) {
    const fileInputRef = useRef();
    const { _t } = useTranslate();
    const [deletedFile, setDeletedFile] = useState(false);

    // States
    const [dragging, setDragging] = useState(false);
    const [valid, setValid] = useState(undefined);
    const [previewURL, setPreviewURL] = useState(props?.placeholder || '');
    const [error, setError] = useState(props.errorMessage || _t('file.select'));

    useEffect(() => {
        setPreviewURL(props?.placeholder || '');
    }, [props.placeholder]);

    function validate(files) {
        const fileStore = [];
        // check for max files
        if (files.length + (props.value?.length || 0) > props.max) {
            setValid(false);
            setError(_t('file.maxOf', { max: props.max }));
            return false;
        } else {
            // check files exist
            if (files.length) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const type = file.type.substring(file.type.indexOf('/') + 1).toString();

                    // validate file type
                    if (props.accept?.length && !props.accept.includes(type)) {
                        setValid(false);
                        setError(
                            '.' +
                                file.type.substring(file.type.indexOf('/') + 1) +
                                ' ' + _t('file.notAllowed').toLowerCase(),
                        );
                    }

                    // validate file size (in mb)
                    else if (props.maxFileSize && file.size > 1048576 * props.maxFileSize) {
                        setValid(false);
                        setError(`${_t('file.maxSize')}: ${props.maxFileSize}mb`);
                    } else {
                        // store the file in form store
                        setValid(true);
                        setDeletedFile(false);
                        fileStore.push({
                            name: file.name,
                            data: file,
                            url: URL.createObjectURL(file),
                            size: file.size,
                            type: file.type,
                        });
                        setPreviewURL(URL.createObjectURL(file));
                    }
                }

                props.onChange(props.name, fileStore, valid);
            }
        }
    }

    function deleteFile(file) {
        fileInputRef.current.value = '';
        setPreviewURL('');
        props.onChange(
            props.name,
            [
                {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                },
            ],
            true,
        );
        // props.onChange(props.name, '', undefined);
    }

    function onDrag(e, state) {
        e.preventDefault();
        e.stopPropagation();
        setDragging(state);
    }

    const fileStyle = ClassHelper(Style, {
        dropzone: true,
        dragging: dragging,
        error: valid === false ? true : false,
        success: props.value?.length && valid === true,
    });

    const imageStyle = {
        backgroundImage: `url(${previewURL})`,
        color: previewURL && 'white',
    };

    function deleteCurrentFile() {
        fileInputRef.current.value = '';
        setPreviewURL('');
        setDeletedFile(true);
        props.onChange('logo', [{}], true);
    }

    return (
        <div className={Style.file}>
            {props.label && <Label text={props.label} required={props.required} for={props.name} />}

            <div
                className={`${fileStyle} ${styles.x} overflow-hidden`}
                style={imageStyle}
                onClick={(e) => fileInputRef.current.click()}
                onDragOver={(e) => onDrag(e, true)}
                onDragEnter={(e) => onDrag(e, true)}
                onDragLeave={(e) => onDrag(e, false)}
                onDrop={(e) => {
                    onDrag(e, false);
                    validate(e.dataTransfer.files);
                }}>
                {/* fallback for old browsers */}
                <input
                    type="file"
                    className={Style.legacyInput}
                    files={props.value}
                    ref={fileInputRef}
                    onChange={(e) => {
                        validate(fileInputRef.current.files);
                    }}
                />

                <div className={Style.label}>
                    <Icon
                        image={props.value?.length && !deleteFile ? 'check' : 'upload'}
                        className={Style.labelIcon}
                    />

                    {props.value?.length && !deletedFile ? (
                        <FileList files={props.value} delete={deleteFile} />
                    ) : (
                        <>
                            <div className={Style.labelText}>{_t('file.dropLogoHere')}</div>
                            {previewURL && (
                                <Button
                                    icon="x"
                                    color="#d95565"
                                    size={15}
                                    className={Style.fileListButton}
                                    action={deleteCurrentFile}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>

            {valid === false && <Error message={error} />}
        </div>
    );
}

function FileList(props) {
    const length = props.files.length - 1;

    if (!props.files?.length) return false;

    return (
        <div>
            <div key={props.files[length].name} className={Style.fileListItem}>
                <span>{props.files[length].name}</span>

                <Button
                    icon="x"
                    color="#d95565"
                    size={15}
                    className={Style.fileListButton}
                    action={(e) => props.delete(props.files[0])}
                />
            </div>
        </div>
    );
}
