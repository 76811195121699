import React, { useState } from 'react';
import { Form, useNavigate, Card, Message } from 'components/lib';
import { useTranslate } from 'app/translations';
import { useParams } from 'react-router-dom';

export const ProjectMagic = () => {
    const { name } = useParams();
    const { _t } = useTranslate();
    const navigate = useNavigate();
    const [magicSent, setMagicSent] = useState(false);

    const beautifyProjectName = (name) => {
        const words = name.split('+');
        return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    const projectName = beautifyProjectName(name);

    return (
        <Card
            restrictWidth
            shadow
            center
            title={_t('signin.to') + ' ' + projectName}
            className="border m-24 mb-24">
            {magicSent ? (
                <Message title={_t('email.check')} type="success" text={_t('email.linkInfo')} />
            ) : (
                <Form
                    data={{
                        email: {
                            label: _t('email.label'),
                            type: 'email',
                            required: true,
                        },
                    }}
                    method="POST"
                    url={`/api/auth/project/${projectName}/magic`}
                    buttonText={_t('submit')}
                    auth={false}
                    callback={(res) => {
                        if (res.status === 200) {
                            const data = res.data;
                            if (!data.user) {
                                // Register the new contact
                                navigate(
                                    `/signup/project?email=${data.email}&account=${data.accountId}&project=${name}`,
                                );
                            } else {
                                setMagicSent(true);
                            }
                        }
                    }}
                />
            )}
        </Card>
    );
};
