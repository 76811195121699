import React from 'react';
import { _t } from 'app/translations';
const Style = {
    container: 'flex flex-wrap w-full',
};

const fieldsString = {
    category: _t('sync.category'),
    status: _t('sync.status'),
    due_date: _t('sync.due_date'),
    url: _t('sync.url'),
    integration: _t('sync.integration'),
};

export const Sync = (props) => {
    const { historyData } = props;
    const { after } = historyData;

    return (
        <div className={Style.container}>
            {after.map((field, index) => {
                return <div key={index}>{fieldsString[field]}</div>;
            })}
        </div>
    );
};
