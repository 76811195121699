import { useContext } from 'react';
import {
    useProjectContext,
    ViewContext,
    Card,
    SettingsWrapper,
    Table,
    Button,
    Animate,
    TitleRow,
    useNavigate,
    useLocation,
} from 'components/lib';
import { useQueryClient } from 'react-query';
import { useTranslate } from 'app/translations';

export function Projects(props) {
    
    // Hooks
    const { _t } = useTranslate();
    const navigate = useNavigate();
    const location = useLocation();

    const queryClient = useQueryClient();

    // Context
    const context = useContext(ViewContext);
    const { projects, updateAllProjects, loading, selectAllProjects, selectedProject } = useProjectContext();

    // Actions function
    function createProject() {
        context.modal.show(
            {
                title: _t('project.create.label'),
                form: {
                    logo: {
                        label: 'Logo',
                        type: 'logo',
                        required: false,
                        placeholder: '',
                    },
                    name: {
                        label: _t('project.name'),
                        type: 'text',
                        required: true,
                    },
                    privacy: {
                        label: _t('project.privacy.label'),
                        type: 'select',
                        required: true,
                        options: [
                            { value: 'privat_domain', label: _t('project.privacy.private') },
                            { value: 'public', label: _t('project.privacy.public') },
                        ],
                        default: 'private',
                    },
                },
                buttonText: _t('save'),
                url: `/api/project`,
                method: 'POST',
            },
            (form, res) => {
                updateAllProjects(false);
                queryClient.invalidateQueries('projects');
            },
        );
    }

    function deleteProject(data, callback) {
        const isCurrentProject = data.id === selectedProject?.id;
        context.modal.show(
            {
                title: _t('project.delete.label'),
                form: {},
                buttonText: _t('project.delete.label'),
                text: _t('project.delete.confirm', { name: data.name }),
                url: `/api/project/${data.id}`,
                method: 'DELETE',
                destructive: true,
            },
            (res) => {
                context.notification.show(
                    data.name + ' ' + _t('message.wasDeleted'),
                    'success',
                    true,
                );
                callback(res);
                queryClient.invalidateQueries('projects');

                if(isCurrentProject) selectAllProjects();          
            },
        );
    }

    const formattedProjects = projects?.map((project) => {
        return {
            ...project,
            privacy: _t(`project.privacy.${project.privacy}`),
        };
    });

    return (
        <SettingsWrapper>
            <Animate>
                <Card>
                    <TitleRow title={_t('project.manage.label')}>
                        <Button small text={_t('project.create.label')} action={createProject} />
                    </TitleRow>
                    <Table
                        search
                        className="restrict-width"
                        loading={loading}
                        data={formattedProjects}
                        show={['name', 'users_count', 'privacy']}
                        actions={{
                            custom: [
                                {
                                    title: _t('project.edit.label'),
                                    icon: 'edit',
                                    action: (data) => {
                                        navigate(`${location.pathname}/${data.id}`, {
                                            state: { title: data.name },
                                        });
                                    },
                                }
                            ],
                            delete: deleteProject,
                        }}
                    />
                </Card>
            </Animate>
        </SettingsWrapper>
    );
}
