/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import { Link, ClassHelper } from 'components/lib';
import LogoWhite from './images/logo-white.png';
import Style from './logo.tailwind.js';
import {rootPath} from 'utilities/routes.js';

export function Logo(props){


  const logoStyle = ClassHelper(Style, props);
  
  return(
    <Link url={rootPath('/')} className={ logoStyle }>
      <img src={LogoWhite} alt='Logo' className="w-48" />
    </Link>
  )
}
