import React from 'react';
import {  Badge } from 'components/lib';

const Style = {
    container: 'flex flex-wrap w-full',
};

export const TaskMention = (data) => {
    return (
        <div className={Style.container}>
            <a href={data.url} target="_blank" rel="noreferrer" title="More details">
                <Badge
                    color="blue"
                    text={data.name}         
                    className="flex w-fit items-center mb-2 lowercase"
                    colorIcon="light"
                />
            </a>
        </div>
    );
};
