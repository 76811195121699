/***
 *
 *   ONBOARDING
 *   Flow to help users set up the app, accepts multiple views/steps
 *   On finish/cancel, the user will be marked as onboarded
 *
 *   PROPS
 *   views: array of views to render containing keys:
 *     name, description and component to render
 *
 **********/

import { useState, useEffect, useContext } from 'react';
import { Animate, CheckList, Button, Logo, useNavigate } from 'components/lib';
import Style from './onboarding.tailwind.js';
import { useApiContext } from 'app/api.js';
import { AuthContext} from 'app/auth';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslate } from 'app/translations.js';
export function Onboarding(props) {
    const navigate = useNavigate();
    const queryClient = useQueryClient()
    const { _t } = useTranslate();
    const user = useQuery('user', () => callApi({ url: '/api/user' }));

    const account = user.data?.data.accounts.find((account) => account?.id === user?.data.data.account_id);
 
    const [activeView, setActiveView] = useState(0);
    const [view, setView] = useState(props.views[activeView]);
    const { postEvent, callApi } = useApiContext();
    const { setUserOnboarded } = useContext(AuthContext);

    const { mutate: updateStep } = useMutation(
        async (step) => {
            callApi({
                method: 'put',
                url: '/api/account/step',
                data: { step },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
        {
            onSuccess: (data, variables) => {
                
                setActiveView(variables);
                // re-fetch auth data
                queryClient.invalidateQueries('auth');
            },
        },
    );

    const { mutate: finish } = useMutation(
        async () => {
            if (props.save)
                await callApi({
                    method: 'patch',
                    url: '/api/user',
                    data: { onboarded: true },
                });
        },
        {
            onSuccess: (data) => {
                setUserOnboarded(true);
                navigate(props.onFinish || '/app/tickets');
            },
        },
    );
 

    useEffect(() => {
        if (account) {
            setActiveView(account.setup_step);
        }
    }, [account]);

    useEffect(() => {
        setView(props.views[activeView]);
    }, [activeView]);
    
    if (!view) return false;

    function goTo(view) {
        const activeViewParams = props.views[activeView];

        if (!activeViewParams.isComplete && view > activeView) return;

        window.location.hash = view + 1;
        setActiveView(view);
    }

    function next() {
        const view = activeView + 1;
        window.location.hash = view + 1;
        updateStep(view);
    }

    function complete() {
        const view = activeView + 1;
        updateStep(view);

        setView((prev) => {
            return { ...prev, isComplete: true };
        });
    }

    return (
        <Animate type="pop-center">
            <div className={Style.onboarding}>
                <section className={Style.sidebar}>
                    <Logo className={Style.logo} />

                    <CheckList
                        color="white"
                        className={Style.checklist}
                        hideCross
                        interactive
                        items={props.views.map((view, i) => {
                            return { name: view.name, checked: i <= activeView ? true : false };
                        })}
                    />
                </section>

                <section className={Style.main}>
                    <header className={Style.header}>
                        <h2 className={Style.name}>{view.name}</h2>

                        {view.description && (
                            <span className={Style.description}>{view.description}</span>
                        )}
                    </header>

                    <article className={Style.article}>
                        {
                            <view.component
                                next={next}
                                complete={complete}
                                finish={finish}
                                isComplete={view.isComplete}
                                account={account}	
                            />
                        }
                    </article>
                </section>

                <nav className={Style.nav}>
                    {activeView > 0 && (
                        <Button
                            icon="chevron-left"
                            alignLeft
                            color="dark"
                            size={16}
                            text={_t('previous')}
                            className={Style.prev}
                            action={() => goTo(activeView - 1)}
                        />
                    )}
                    {view.isComplete && activeView < props.views.length - 1 && (
                        <Button
                            icon="chevron-right"
                            alignRight
                            color="dark"
                            size={16}
                            text={_t('next')}
                            className={Style.next}
                            action={() => {
                                goTo(activeView + 1);
                            }}
                        />
                    )}

                    {activeView === props.views.length - 1 && (
                        <Button
                            icon="check-circle"
                            alignRight
                            color="dark"
                            size={16}
                            text="Finish"
                            className={Style.next}
                            action={() => {
                                postEvent('completed_onboarding');
                                finish();
                            }}
                        />
                    )}
                </nav>
            </div>
        </Animate>
    );
}
