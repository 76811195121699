/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/

import React, { useContext, useState, useEffect,useMemo } from 'react';
import {
    ViewContext,
    Card,
    SettingsWrapper,
    Table,
    Button,
    Animate,
    TitleRow,
    usePermissions,
    useNavigate,
    useProjectContext,
} from 'components/lib';
import { useTranslate } from 'app/translations';
import { useApiContext } from 'app/api';
import { useMutation } from 'react-query';
import { useUsers } from '../../../components/hooks/users';

export function Users() {
    const { _t } = useTranslate();
    const context = useContext(ViewContext);
    const permissions = usePermissions();
    const navigate = useNavigate();
    const { projects } = useProjectContext();
    const usersData = useUsers(null);

    const [users, setUsers] = useState([]);
    const { postEvent, callApi } = useApiContext();

    const { mutate: resendInvite } = useMutation(
        async (data) => {
            await callApi({
                url: '/api/invite?target=user',
                method: 'post',
                data: { email: data.email },
            });
        },
        {
            onSuccess: (data, variables) => {
                context.notification.show(
                    _t('invite.reSendTo', {
                        email: variables.email,
                    }),
                    'success',
                    true,
                );
            },
            onError: (err) => {
                context.handleError(err);
            },
        },
    );

    function invite() {
        context.modal.show(
            {
                title: _t('user.add.one'),
                form: {
                    email: {
                        label: _t('email.label'),
                        type: 'text',
                        required: true,
                    },
                    permission: {
                        label: _t('permission.label'),
                        type: 'select',
                        default: 'user',
                        options: permissions?.data?.list
                            ?.map((x) => {
                                // Translate
                                return { value: x.value, label: _t(`permissions.${x.value}`) };
                            })
                            .filter((x) => x.value !== 'owner'),
                    },
                    project_id: {
                        label: _t('project.label.one'),
                        type: 'select',
                        options: projects?.map((x) => {
                            return { value: x.id, label: x.name };
                        }),
                    },
                },
                buttonText: _t('invite.send'),
                text: _t('invite.sendInfo'),
                url: '/api/invite?target=user',
                method: 'POST',
            },
            () => {
                usersData.refetch();
                postEvent('invited_user');
            },
        );
    }

    function editUser(user) {
        navigate(`/app/settings/users/${user.id}`, {
            state: {
                title: user.first_name,
            },
        });
    }

    function deleteUser(data) {
        context.modal.show(
            {
                title: _t('user.delete.label'),
                form: {},
                buttonText: _t('user.delete.label'),
                text: _t('user.delete.confirm', { name: data.name }),
                url: `/api/user/${data.id}/organization`,
                method: 'DELETE',
                destructive: true,
            },
            () => {
                context.notification.show(_t('message.wasDeleted'), 'success', true);
                usersData.refetch();
            },
        );
    }

    function deleteInvite(data) {
        context.modal.show(
            {
                title: _t('invite.delete.label'),
                form: {},
                buttonText: _t('invite.delete.label'),
                text: _t('invite.delete.confirm', { email: data.email }),
                url: `/api/invite/${data.id}`,
                method: 'DELETE',
                destructive: true,
            },
            () => {
                context.notification.show(`${_t(`invite.wasDeleted`)}`, 'success', true);
                usersData.refetch();
            },
        );
    }

    useEffect(() => {
        // format the user list
        let list = [];

        if (usersData.data?.data.users?.length) {
            list = usersData.data.data.users.map((x) => {
                return {
                    id: x.id,
                    first_name: x.first_name,
                    last_name: x.last_name,
                    email: x.email,
                    date_created: x.date_created,
                    permission: _t(`permissions.${x.permission}`),
                    status: _t('table.badge.registered'),
                };
            });
        }

        if (usersData?.data?.data.usersInvites?.length) {
            usersData.data.data.usersInvites.forEach((x) => {
                list.push({
                    id: x.id,
                    first_name: '',
                    last_name: '',
                    email: x.email,
                    date_created: x.date_sent,
                    permission: _t(`permissions.${x.permission}`) || _t('permissions.user'),
                    status: _t('table.badge.invited'),
                });
            });
        }

        setUsers(list);
    }, [usersData.data, _t]);

    const formattedUsers = useMemo(() => {
        return users.filter((x) => x.permission !== _t(`permissions.owner`));
    }, [users]);

    return (
        <SettingsWrapper>
            <Animate>
                <TitleRow title={_t('user.manage.label.multiple')}>
                    <Button small text={_t('user.add.one')} action={invite} />
                </TitleRow>

                <Card>
                    <Table
                        search
                        className="restrict-width"
                        data={formattedUsers}
                        loading={usersData.isLoading}
                        show={[
                            'email',
                            'first_name',
                            'last_name',
                            'last_login',
                            'permission',
                            'status',
                        ]}
                        badge={{
                            col: 'status',
                            color: 'blue',
                            condition: [
                                { value: _t('table.badge.registered'), color: 'green' },
                                { value: _t('table.badge.invited'), color: 'blue' },
                            ],
                        }}
                        actions={{
                            custom: [
                                {
                                    icon: 'edit',
                                    title: _t('edit'),
                                    action: editUser,
                                    conditions: [
                                        { col: 'status', value: _t('table.badge.registered') },
                                    ],
                                },
                                {
                                    icon: 'mail',
                                    title: _t('invite.send'),
                                    action: resendInvite,
                                    conditions: [
                                        { col: 'status', value: _t('table.badge.invited') },
                                    ],
                                },
                                {
                                    icon: 'trash',
                                    title: _t('delete'),
                                    action: deleteUser,
                                    conditions: [
                                        { col: 'status', value: _t('table.badge.registered') },
                                    ],
                                },
                                {
                                    icon: 'trash',
                                    title: _t('delete'),
                                    action: deleteInvite,
                                    conditions: [
                                        { col: 'status', value: _t('table.badge.invited') },
                                    ],
                                },
                            ],
                        }}
                    />
                </Card>
            </Animate>
        </SettingsWrapper>
    );
}
