import React, { useState, useEffect } from 'react';
import { useTranslate } from 'app/translations';

const Style = {
    date: 'text-xs text-gray-500 whitespace-nowrap',
}

const timeUnits = [
    { name: 'day', seconds: 60 * 60 * 24 },
    { name: 'hour', seconds: 60 * 60 },
    { name: 'minute', seconds: 60 },
    { name: 'second', seconds: 1 },
];

const calculateTimePassed = (date, timeUnits, translateFn, lang) => {
    const dateObj = new Date(date);
    const secondsPassed = Math.floor((new Date() - dateObj) / 1000);

    for (const unit of timeUnits) {
        if (secondsPassed >= unit.seconds) {
            const amount = Math.floor(secondsPassed / unit.seconds);
            const eng = `${amount} ${translateFn(`ticket.history.${unit.name}`)}${amount > 1 ? 's' : ''} ${translateFn('ticket.history.ago')}`;
            const fr = `il y a ${amount} ${translateFn(`ticket.history.${unit.name}`)}${amount > 1 ? 's' : ''}`;
            return lang === 'fr' ? fr : eng;
        }
    }
    return translateFn('ticket.history.justNow');
};

export const Time = ({ date, lastEdit, className }) => {
    const { _t } = useTranslate();

    const browserLanguage = navigator.language.split('-')[0];
    const lang = JSON.parse(localStorage.getItem('user'))?.language || browserLanguage;

    const formattedDate = new Date(date).toLocaleString(lang, {
        year: 'numeric', 
        month: 'long', 
        day: 'numeric',
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: true 
    });

    const [timePassed, setTimePassed] = useState(() => calculateTimePassed(date, timeUnits, _t, lang));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimePassed(calculateTimePassed(date, timeUnits, _t, lang));
        }, 100000);

        return () => {
            clearInterval(timer);
        };
    }, [date, _t, lang]);

    return (
        <span className={`${Style.date} ${Style.hover} ${className}`} title={formattedDate}>
            {lastEdit && _t('ticket.history.lastEdit')} {timePassed}
        </span>
    );
};
