import React from 'react';
import { CommonAttachment } from '../general/attachment';
import { useTranslate } from 'app/translations';

const Style = {
    container: 'flex-col w-full mb-2',
}; 

export const Attachment = ({ historyData }) => {
    const { attachments } = historyData;
    const { _t } = useTranslate();
    return (
        <>
            <div className={Style.container}>
                <p className="flex gap-1">
                    <span>{_t('ticket.history.uploadedFiles').toLowerCase()}</span>
                    <strong>{attachments.length}</strong>
                    <strong>{_t('ticket.history.file').toLowerCase()}{attachments.length > 1 && "s"}</strong>
                </p>
            </div>
            
            <div className={'flex gap-1 relative right-5'}>
                {attachments.map((attachment, index) => (
                    <CommonAttachment attachment={attachment} key={index} />
                ))}                 
            </div>
        </>
    );
};
