const Style = {
    container: 'flex w-full h-full',
    main: 'w-2/3 border-r border-gray-200 h-full flex flex-col',
    titleContent: 'flex justify-between items-center p-4 border-b border-gray-200',
    title: 'text-2xl font-semibold',
    date: 'text-slate-500',
    side: 'w-1/3 h-full overflow-y-auto',
    hr: 'border-slate-200 my-4',
};

export default Style;