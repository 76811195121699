/***
*
*   CONTACT FORM
*   Will send an email notification to you
*
**********/

import React from 'react';
import { Row, ContactForm } from 'components/lib';
import { useTranslate } from 'app/translations';

export function Contact(props){
  const { _t } = useTranslate();

  return(
    <Row title={_t('contact.title')}>
      <ContactForm />
    </Row>
  )
}
