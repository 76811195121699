const Style = {
    history: 'flex flex-col rounded-lg mb-4 space-x-4 w-[95%] max-w-[700px] mx-auto text-sm',
    container: 'flex justify-between space-x-1 items-start space-between',
    name: 'text-md font-semibold text-vibrant-300',
    date: 'text-gray-500 whitespace-nowrap',
    info: 'flex space-x-1 items-start space-between',
    divider: 'border-t my-1 border-slate-300',
    event: 'flex-col'
}

export default Style