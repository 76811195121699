import React from 'react';
import { useTranslate } from 'app/translations';

const Style = {
    container: 'flex flex-wrap w-full',
};

export const Created = () => {
    const { _t } = useTranslate();
    return (
        <div className={Style.container}>
            <span>
                {_t('ticket.history.event.ticket.created').toLowerCase()}
            </span>
        </div>
    );
};
