import { useEffect, useContext } from 'react';
import {
    Animate,
    SettingsWrapper,
    useProjectContext,
    useBreadcrumbsContext,
    Link,
    Button,
    ViewContext,
} from 'components/lib';
import { useParams, useLocation } from 'react-router-dom';
import { ProjectForm } from 'components/form/template/project';
import { useUsers, useProjectUsers } from '../../../components/hooks/users';
import ProjectUsers from './users';
import ProjectContacts from './contacts';
import { useTranslate } from 'app/translations';

export function EditProject() {
    const { id } = useParams();
    const { state } = useLocation();
    const { projects, selectAllProjects, selectedProject } = useProjectContext();
    const { pushParams } = useBreadcrumbsContext();
    const { _t } = useTranslate();
    const projectUsers = useProjectUsers(id);
    const users = useUsers(id);
    const viewContext = useContext(ViewContext);
    const project = projects?.find((p) => p.id === id);
    const isCurrentProject = selectedProject?.id === id;

    useEffect(() => {
        if (project) {
            pushParams({
                id: project.name,
            });
        }
    }, [project]);

    return (
        <SettingsWrapper>
            <Animate>
                <ProjectForm method="PATCH" url={`/api/project/${id}`} id={id}>
                    <div className="flex flex-col justify-center items-center py-4 space-y-4">
                        <br />
                        <Link
                            className="text-gray-500 underline"
                            url={`/project/${project?.name}`}
                            text={_t('project.viewPage')}
                        />

                        <Button
                            color="red"
                            text={_t('project.delete.label')}
                            action={() => {
                                viewContext.modal.show(
                                    {
                                        title: _t('project.delete.label'),
                                        form: {},
                                        text: _t('project.delete.confirm'),
                                        buttonText: _t('project.delete.label'),
                                        url: `/api/project/${id}`,
                                        destructive: true,
                                        method: 'DELETE',
                                    },
                                    (res) => {
                                        if(res.status === 200)
                                        if(isCurrentProject) selectAllProjects();
                                        window.location.href = '/app/settings/projects';
                                    },
                                );
                            }}
                        />
                    </div>
                </ProjectForm>
                {!projectUsers.isLoading && !users.isLoading && (
                    <>
                        <ProjectUsers
                            users={users}
                            projectUsers={projectUsers}
                            project={state?.title}
                        />
                        <ProjectContacts
                            users={users}
                            projectUsers={projectUsers}
                            project={state?.title}
                        />
                    </>
                )}
            </Animate>
        </SettingsWrapper>
    );
}
