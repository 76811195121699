import React from 'react';
import { Name } from '../general/name';
import { useTranslate } from 'app/translations';

const Style = {
    container: 'flex flex-wrap w-full',
};

export const Assignee = ({ actionType, historyData, user }) => {
    const { _t } = useTranslate();
    const { before, after } = historyData;

    return (
        <div className={Style.container}>
            {actionType === 'assignee_add' ? (
                <p className="flex gap-1">{_t('ticket.history.event.assignee.added').toLowerCase()}
                    <span className="text-secondary-600">
                        <Name user={user} target={after} />
                    </span>
                </p>
                ) : actionType === 'assignee_rem' && (
                    <p className="flex gap-1">
                        {_t('ticket.history.event.assignee.removed').toLowerCase()}
                        <span className="text-secondary-600">
                            <Name user={user} target={before} />
                        </span>
                    </p>
                )
            }         
        </div>
    );
};
