/***
 *
 *   BUTTON
 *   Can be a standard button, icon button or with loading animation
 *
 *   PROPS
 *   text: button label
 *   action: callback function executed on click
 *   params: object passed to the callback function (optional)
 *   color: red/blue (default: green)
 *   icon: icon image (optional)
 *   iconPack: icon pack to use
 *   iconSize: icon size
 *   iconButton: true or false
 *   alignIcon: left or right
 *   small: render a smaller button
 *   textOnly: text only
 *   outline: outline button
 *   rounded: round the corners
 *   className: pass a custom class object
 *   fullWidth: extend to full width of parent container
 *   isLoading: boolean to toggle loading animation (optional)
 *   count: number to display in a badge (optional)
 *
 **********/

import { Fragment } from 'react';
import { Icon, ClassHelper, useNavigate, Loader } from 'components/lib';

import ButtonStyle from './button.tailwind.js';
import IconStyle from './icon.tailwind.js';

export function Button(props) {
    const navigate = useNavigate();

    const buttonStyle = ClassHelper(ButtonStyle, {
        ...props,
        ...{
            [props.color]: props.color,
            [props.position || 'relative']: true,
            text: props.textOnly,
            iconButton: props.icon && !props.text,
            iconText: props.icon && props.text,
            iconTextOnly: props.textOnly && props.icon && props.text,
            btn: props.iconButton || (!props.textOnly && !props.icon),
            ...(!props.color &&
                props.text &&
                !props.color &&
                !props.icon &&
                !props.textOnly &&
                !props.outline && {
                    // default color
                    green: true,
                }),
        },
    });

    const iconStyle = ClassHelper(IconStyle, {
        fill: props.fillIcon,
        alignLeft: props.alignLeft,
        alignRight: props.alignRight,
        insideButton: props.iconButton || (!props.textOnly && !props.icon),
    });

    return (
        <button
            title={props.title}
            disabled={props.disabled}
            className={buttonStyle}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                props.action && props.action(props.params);
                props.goto && navigate(props.goto);
                if (props.url) window.location = props.url;
            }}>
            {props.count > 0 && (
                <span className="absolute top-0 left-0 -mt-1 -ml-1 bg-red-400 text-white text-xs font-bold rounded-full px-1">
                    {props.count}
                </span>
            )}
            {props.isLoading ? (
                <div className="flex items-center justify-center">
                    <Loader
                        size={18}
                        absoluteStyle={false}
                    />
                </div>
            ) : props.icon ? (
                <Fragment>
                    <Icon
                        image={props.icon}
                        pack={props.iconPack}
                        color={props.iconColor || props.color}
                        size={props.iconSize || props.size || 18}
                        className={props.iconClassName || iconStyle}
                    />
                    {props.text && props.text}
                </Fragment>
            ) : (
                props.text
            )}
        </button>
    );
}
