/***
*
*   SIGN UP STEP 2
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext } from 'react';
import { Animate, Row, Card, PaymentForm, usePlans, Link, useNavigate } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';
import { useApiContext } from 'app/api';

export function SignupPlan(props){
  const {_t} = useTranslate();
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const plans = usePlans();
  const { postEvent } = useApiContext();

  const plan = window.location.hash.substring(1);

  if (!plans.data)
    return false;

  return(
    <Animate type='pop'>
      <Row title={_t('plan.select')}>
        <Card loading={ false } restrictWidth center>

          <PaymentForm
            data={{
              plan: {
                label: _t('plan.label'),
                type: 'select',
                options: plans.data.list,
                default: plan,
                required: true,
              },
              token: {
                label: _t('creditCard.label'),
                type: 'creditcard',
                required: true,
              }
            }}
            url='/api/account/plan'
            method='POST'
            buttonText={_t('plan.save')}
            callback={ res => {

              // save the plan to context, then redirect
              postEvent('selected_plan', { plan: res.data.plan });
              context.update({ plan: res.data.plan, subscription: res.data.subscription });
              navigate(res.data.onboarded ? '/app/tickets' : '/app/onboarding');

            }}
          />
        </Card>
      </Row>
    </Animate>
  );
}
