import React, { useContext } from 'react';
import Style from './owner.tailwind';
import { useTranslate } from 'app/translations';
import { Button, ViewContext } from 'components/lib';
import { AuthContext } from 'app/auth';

const TicketOwner = ({ ticket }) => {
    const { _t } = useTranslate();
    const viewContext = React.useContext(ViewContext);
    const creationHistory = ticket.histories.find((history) => history.action_type === 'created');
    const {
        user: { permission },
    } = useContext(AuthContext);
    if (!creationHistory) return null;

    return (
        <div className={Style.container}>
            <h3 className={Style.title}>{_t('ticket.by')}</h3>
            <div className={Style.item}>
                <span className={Style.itemTitle}>{_t('name.label')}</span>
                <p>{creationHistory.creator_name}</p>
            </div>
            <div className={Style.item}>
                <span className={Style.itemTitle}>{_t('email.label')}</span>
                <p>{creationHistory.creator_email}</p>
            </div>
            {permission !== 'contact' && (
                <Button
                    text={_t('profile.label')}
                    small
                    color="blue"
                    className="text-md w-fit mx-auto min-w-[150px]"
                    goto={`/app/contacts/${ticket.user_id}`}
                    action={() => {
                        viewContext.pannel.hide();
                    }}
                />
            )}
        </div>
    );
};

export default TicketOwner;
