/***
 *
 *   BREADCRUMBS
 *   Navigation trail for nested pages.
 *
 *   PROPS
 *   items: array of objects containing keys: name and url
 *
 ***/

import { Link, useBreadcrumbsContext } from 'components/lib';
import Style from './breadcrumbs.tailwind.js';

export function Breadcrumbs(props) {
    const { items } = props;
    const { paramsList } = useBreadcrumbsContext();

    return (
        <nav className={Style.breadcrumbs}>
            {items || paramsList.map((item, key) => {
                const lastItem = key === paramsList.length - 1;
                const active = lastItem || item.isActive ? Style.active : '';
                
                if(!item.name) return null;

                return (
                    <Link
                        className={`${Style.link} ${active}`}
                        key={key}
                        url={item.url}
                        text={item.name}
                    />
                );
            })}
        </nav>
    );
}
