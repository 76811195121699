import { useState, useContext } from 'react';
import { Label, Error, Icon, ClassHelper, Badge, ViewContext } from 'components/lib';
import { useTranslate } from 'app/translations.js';
import { useApiContext } from 'app/api';
import { useMutation, useQueryClient } from 'react-query';
import Style from './input.tailwind.js';
import Styles from './input.module.scss';
import { useLiveQuery } from 'components/hooks/websocket.js';

export function TextListInput(props) {
    const { _t } = useTranslate();
    const error = props.errorMessage || _t('value.empty');
    const queryClient = useQueryClient();
    const context = useContext(ViewContext);
    const { callApi } = useApiContext();
    
    // States
    const [errorMessage, setErrorMessage] = useState(null);
    
    const { data: list, isLoading } = useLiveQuery(
        props.slug,
        {
            url: props.url,
            project_id: props.url.split('/').pop(),
        },
        {
            select: (res) => {
                return res.data.filter((item) => item.hasOwnProperty(props.name));
            },
        },
    );

    const { mutate: addToList } = useMutation(
        'addToList',
        () => callApi({ url: props.url, method: 'POST', data: { [props.name]: props.value } }),
        {
            onSuccess: (res) => {
                if (props.value && res.status === 200) {
                    props.onChange(props.name, '', undefined);
                    queryClient.invalidateQueries(props.slug);
                } else {
                    context.handleError({
                        data: {
                            message: res.data.message,
                        },
                        status: true,
                    });
                }
            },
        },
    );

    // style
    const textStyle = ClassHelper(Style, {
        textbox: true,
        className: props.className,
        error: props.valid === false,
        success: props.valid === true,
        warning: props.warning,
    });

    if (isLoading) return null;

    return (
        <div className={Style.input}>
            {props.label && <Label text={props.label} required={props.required} for={props.name} />}

            <div className={Styles.textList}>
                <input
                    type="text"
                    id={props.id}
                    name={props.name}
                    value={props.value || ''}
                    className={textStyle}
                    placeholder={props.placeholder}
                    onChange={(e) => props.onChange?.(props.name, e.target.value, undefined)}
                    onBlur={(e) => {
                        if (props.onBlur) {
                            setErrorMessage(props.onBlur(e));
                        }
                    }}
                />
                <div
                    className={Styles.inputIcon}
                    onClick={() => {
                        if (!errorMessage && props.value) {
                            addToList();
                        } else {
                            context.handleError({
                                data: {
                                    message: errorMessage || error,
                                },
                                status: true,
                            });
                        }
                    }}>
                    <Icon image="plus" size={20} />
                </div>
            </div>

            {props.valid === false && <Error message={error} />}

            {props.domain && (
                <div>
                   {_t("example")}: {props.value}
                    {props.domain}
                </div>
            )}

            <div className="mt-2 flex flex-wrap">
                {list.map((item, key) => (
                    <Badge
                        color="blue"
                        text={`${item[props.name]}`}
                        key={key}
                        icon="x"
                        className="flex w-fit items-center mb-2 lowercase"
                        colorIcon="light"
                        onIconClick={() => {
                            context.modal.show(
                                {
                                    title: _t(`${props.name}.delete`),
                                    form: {},
                                    buttonText: _t('delete'),
                                    destructive: true,
                                    emptyForm: true,
                                    url: props.url,
                                    method: 'DELETE',
                                    injectedData: { ...item, key: props.name },
                                },
                                (res) => {
                                    if (res.status === 200) {
                                        queryClient.invalidateQueries(props.slug);
                                    }
                                },
                            );
                        }}
                    />
                ))}
            </div>

            {props.valid === true && (
                <Icon
                    image="check"
                    color="#8CC57D"
                    className={Style.successIconTextList}
                    size={16}
                />
            )}
        </div>
    );
}
