import { NoProjects } from 'views/error/noProjects';
import { _t } from '../app/translations';

const Routes = [
    {
        path: '/error/no-projects',
        view: NoProjects,
        layout: 'app',
        permission: 'user',
        title: _t('error.project.title'),
    },
];

export default Routes;
