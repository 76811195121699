/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Onboarding, Form, Message } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate, _t } from 'app/translations';
import { useApiContext } from 'app/api';
import { useQuery, useMutation } from 'react-query';
import { ProjectForm } from 'components/form/template/project';
import Style from './onboarding.tailwind';
import settings from '../../settings';

const { client_url_main } = settings;

export function OnboardingView(props) {
    const context = useContext(AuthContext);
    const { callApi } = useApiContext();

    const user = useQuery('user', () => callApi({ url: '/api/user' }));
    const account = user.data?.data.accounts.find(
        (account) => account?.id === user?.data.data.account_id,
    );

    const { _t } = useTranslate();

    const views = [
        {
            name: _t('onboarding.start.title'),
            description: _t('onboarding.welcome', {
                name: context.user.first_name,
            }),
            component: Welcome,
            isComplete: true,
        },
    ];

    if (context.permission.user && !context.permission.owner) {
        addView({
            name: _t('setup.label'),
            component: SetupContact,
            isComplete: false,
        });
    }

    if (context.permission.owner && account) {
        addView({
            name: _t('setup.label'),
            description: _t('organization.setup'),
            component: SetupOrganization,
            isComplete: false,
        });

        addView({
            name: _t('service.link.label'),
            description: _t('service.link.description'),
            component: SetupIntegration,
            isComplete: false,
        });

        addView({
            name: _t('project.label.one'),
            description: _t('project.configure'),
            component: SetupProject,
            isComplete: false,
        });

        addView({
            name: _t('onboarding.invite.title'),
            description: _t('onboarding.invite.desc'),
            component: InviteUsers,
            isComplete: true,
        });

        addView({
            name: 'All done!',
            description: 'You are ready to go!',
            component: () => <></>,
            isComplete: true,
        });
    }

    if (context.user.duplicate_user) {
        views.unshift({
            name: 'Important!',
            description: '',
            component: DuplicateUser,
            isComplete: true,
        });
    }

    function addView(view) {
        views.push({ ...view, isComplete: view.isComplete || account?.setup_step > views.length - 1 });
    }

    return <Onboarding save onFinish="/app/tickets" views={views} />;
}

function DuplicateUser() {
    const { _t } = useTranslate();
    return <Message type="warning" title={_t('user.exist')} text={_t('user.existDescription')} />;
}

function Welcome() {
    return (
        <Fragment>
            {/* <p>
                This is an example of the user-onboarding flow. You can replace this with your own
                content, try out the action in the next step or skip the intro entirely.
            </p> */}

            {/* <p>
                <strong>Have fun playing with Valetify! 🛠 </strong>
            </p> */}
        </Fragment>
    );
}

function InviteUsers(props) {
    const { _t } = useTranslate();
    const user = useQuery('user');
    const defaultProject = user.data?.data.default_project;
    return (
        <Form
            data={{
                email: {
                    label: _t('email.label'),
                    type: 'email',
                    required: true,
                }
            }}
            buttonText={_t('invite.send')}
            url="/api/invite?target=user"
            injectedData={{
                project_id: defaultProject,
            }}
            callback={(res) => {
                localStorage.removeItem('project_id');
                props.next();
            }}
            method="POST"
        />
    );
}

function SetupContact(props) {
    const formData = {
        phone: {
            label: 'Phone Number',
            type: 'phone',
            required: false,
        },
        position: {
            label: 'Position',
            type: 'text',
            required: false,
            placeholder: 'Ex: Product Manager',
        },
    };

    return (
        <>
            <Form
                data={formData}
                callback={(res) => {
                    if (res.status === 200) {
                        props.finish();
                    }
                }}
                buttonText={_t('save')}
                url="/api/user"
                method="PATCH"
            />
        </>
    );
}

function SetupIntegration(props) {
    const { _t } = useTranslate();
    const { callApi } = useApiContext();

    const query = useQuery('accountIntegrations', () =>
        callApi({ url: '/api/account/integrations' }),
    );

    useEffect(() => {
        if (query.data?.data.length > 0 && !props.isComplete) {
            props.complete();
        }
    }, [query.data]);

    return (
        <div>
            {query.isLoading ? null : query.data?.data.length > 0 ? (
                <div>{_t('integration.connected')}</div>
            ) : (
                <Form
                    data={{
                        service: {
                            label: 'Services',
                            type: 'select',
                            required: true,
                            value: 'clickup',
                            options: [{ value: 'clickup', label: 'Clickup' }],
                            default: 'clickup',
                        },
                    }}
                    callback={(res) => {
                        if (!res.data.redirect) return;
                        window.location.href = res.data.redirect;
                    }}
                    buttonText={_t('integration.connect')}
                    url="/api/integration/connect"
                    method="POST"
                    headers={{
                        'Redirect-Url': window.location.href,
                    }}
                />
            )}
        </div>
    );
}

function SetupOrganization(props) {
    const context = useContext(AuthContext);
    const { account } = props;

    const formData = {
        logo: {
            label: 'Logo',
            type: 'logo',
            name: 'logo',
            required: false,
            placeholder: account.file_path,
        },
        name: {
            label: _t('organization.name'),
            type: 'text',
            required: true,
            placeholder: 'My organization',
            value: account.name,
        },
        domain: {
            label: _t('domain.label'),
            type: 'domain',
            required: true,
            value: account.domain,
            placeholder: `Ex: my-company-name`,
            domain: `.${client_url_main}`,
        },
    };

    return (
        <>
            <Form
                data={formData}
                callback={(res) => {
                    if (res.status === 200) {
                        props.next();
                        if (res.data.domain !== account.domain) {
                            context.switchDomain(res.data.domain);
                        }
                        account.file_path = res.data.file_path;
                        context.update({ accounts: [account] });
                    }
                }}
                buttonText={_t('save')}
                url="/api/account"
                method="PUT"
            />
        </>
    );
}

function SetupProject(props) {
    const { callApi } = useApiContext();
    const { _t } = useTranslate();
    const user = useQuery('user', () => callApi({ url: '/api/user' }));
    const defaultProject = user.data?.data.default_project;
    const [project, setProject] = useState(null);

    const { mutate: createDefaultProject } = useMutation(
        (data) =>
            callApi({
                url: '/api/project',
                method: 'POST',
                data: {
                    name: _t('project.defaultName'),
                    privacy: 'private',
                },
            }),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    user.refetch();
                    localStorage.setItem('project_id', JSON.stringify(res.data.id));
                    setProject(res.data);
                }
            },
        },
    );

    useEffect(() => {
        // create default project if none exist
        if (!defaultProject) createDefaultProject();
    }, [createDefaultProject, defaultProject]);
    // if exist render form
    return (
        <div className={Style.setupProject}>
            {defaultProject && (
                <ProjectForm
                    method="PATCH"
                    url={`/api/project/${defaultProject}`}
                    id={defaultProject}
                    disableTitle
                    providedProject={project}
                    callback={() => {
                        props.next();
                    }}
                />
            )}
        </div>
    );
}
