import React from 'react';
import { TypesComponent } from './comments';

const Style = {
    container: 'flex',
    list: 'list-disc pl-10',
    listItemContent: 'flex gap-1',
};

export const List = ({ list }) => {
    return (
        <div className={Style.container}>
            <ul className={Style.list}>
                {list?.map((item, index) => (
                    <li key={index}>
                        <div className={Style.listItemContent}>
                            {item.map((subList, i) => {
                                const Component = TypesComponent[subList?.type];
                                if(!Component) return null;
                                return (
                                    <Component key={i} {...subList} />
                                );
                            })}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
