const Style = {
    container: 'flex flex-col bg-main-100/10 shadow-xl rounded-2xl min-w-[250px] border border-main-300/50 mx-2 my-4 position-relative',
    header: 'flex justify-between items-center my-2 px-2 py-1',
    statusItem: 'flex items-center bg-white/80 shadow rounded-2xl p-2 flex-col justify-between mb-2 cursor-pointer hover:bg-slate-100',
    ticketsAmount: 'text-xs text-slate-500',
    dragging: 'bg-white/80',
    title: 'text-lg font-semibold',
    action: 'flex',
    actionBtn: 'transition duration-300 ease-in-out hover:bg-slate-200 rounded-2xl p-1',
};

export default Style;