import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useApiContext } from 'app/api';
import { Form } from 'components/lib';
import { useTranslate } from 'app/translations';
import ClickupIntegrationConnector from './integrationConnector';

const ExistingCategoryConnector = () => {
    const { callApi } = useApiContext();
    const { _t } = useTranslate();
    const [showIntegrationForm, setShowIntegrationForm] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const projectsCategories = useQuery('projectsTicketCategories', () => {
        return callApi({ url: '/api/ticket/category/byProject' });
    });

    const projects = projectsCategories.data?.data;
    const hasMultipleProjects = projects?.length > 1;

    // Check if some projects have categories
    const hasCategories = projects?.some((project) => project.categories.length);

    if (!hasCategories) {
        return (
            <div>
                <h3>{_t('categories.empty')}</h3>
            </div>
        );
    }

    let projectCategories = [];
    let formData = {};

    if (projects) {
        projectCategories = hasMultipleProjects
            ? projects
                  ?.map((project) => {
                      if (!project.categories.length) return null;
                      return {
                          label: project.name,
                          value: project.id,
                          options: project?.categories?.map((c) => ({
                              label: c.name,
                              value: c.id,
                          })),
                      };
                  })
                  .filter((project) => project)
            : projects?.[0]?.categories.map((c) => ({
                  label: c.name,
                  value: c.id,
              }));

        formData.category = {
            type: hasMultipleProjects ? 'selectGroup' : 'select',
            label: _t('categories.label.one'),
            options: projectCategories,
            required: true,
            value: selectedCategory?.id || '',
            onChange: (value) => {
                setShowIntegrationForm(true);
                setSelectedCategory(findSelectedCategory(value));
            },
        };
    }

    const findSelectedCategory = (category) => {
        let selectedCategory = null;
        projects.forEach((project) => {
            project.categories.forEach((c) => {
                if (c.id === category) {
                    selectedCategory = c;
                }
            });
        });
        return selectedCategory;
    };

    return (
        <div>
            <Form data={formData} updateOnChange />
            {showIntegrationForm && (
                <div>
                    <hr className="my-4" />
                    <h3 className="my-4">{_t('ticket.category.connect')}</h3>
                    <ClickupIntegrationConnector
                        method="POST"
                        url="/api/ticket/category"
                        navigateToCategory
                        injectedData={{
                            //TODO: add email json and form json
                            name: selectedCategory.name,
                            email_template: selectedCategory.email_template,
                            form_template: selectedCategory.form_template,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ExistingCategoryConnector;
