/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a tickets link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import { Fragment, useContext } from 'react';
import { AuthContext,} from 'app/auth';
import { Logo, Link, Button, Content, ClassHelper } from 'components/lib';
import Style from './home.tailwind.js';
import { useTranslate } from 'app/translations.js';
import { rootPath } from 'utilities/routes.js';

export function HomeNav(props) {
    const { _t } = useTranslate();
    // context
    const context = useContext(AuthContext);

    const css = ClassHelper(Style, {
        wrapper: true,
        color: !props.transparent,
        transparent: props.transparent,
    });

    return (
        <section className={css}>
            <Content>
                <Logo className={Style.logo} />
                <nav className={Style.nav}>
                    {/* <Link
                        url={rootPath('/pricing')}
                        text={_t('pricing.label')}
                        className={Style.link}
                        color="white"
                    /> */}

                    {context.user?.onboarded ? (
                        <Button
                            small
                            goto="/app/tickets"
                            text={_t('dashboard.label')}
                            className={Style.button}
                        />
                    ) : (
                        <Fragment>
                            <Link
                                url={rootPath('/signin')}
                                text={_t('signin.label')}
                                className={Style.link}
                                color="white"
                            />
                            <Button
                                small
                                url={rootPath('/signup')}
                                text={_t('signup.label')}
                                className="inline-block"
                            />
                        </Fragment>
                    )}
                </nav>
            </Content>
        </section>
    );
}
