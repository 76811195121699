import React, { useState } from 'react';
import Style from './comments.tailwind.js';
import { useApiContext } from 'app/api';
import { Loader, Icon } from 'components/lib';
import { useTranslate } from 'app/translations';
import { Attachment } from './attachment';
import { List } from './list';
import { Tag } from './tag';
import { TaskMention } from './taskMention';
import { Text } from './text';
import { Name } from '../general/name';
import { useQueryClient } from 'react-query';
import { Time } from '../general/time.js';
import { firstLetterToUpperCase } from 'utilities/utils';

export const TypesComponent = {
    attachment: Attachment,
    text: Text,
    list: List,
    tag: Tag,
    task_mention: TaskMention,
};

const parseComments = (comments) => {
    return comments.map((comment, index) => {
        if (comment?.type === 'list') {
            return <List {...comment} key={index} />;
        }
        const { comments } = comment;
        return (
            <div style={{ display: 'flex', gap: '5px' }} key={index}>
                {comments &&
                    comments?.map((c, i) => {
                        const Component = TypesComponent[c?.type];
                        if (!Component) return null;
                        return (
                            <div style={{ display: 'flex' }} key={i}>
                                <Component {...c} key={i} />
                            </div>
                        );
                    })}
            </div>
        );
    });
};

export const CommentsHistory = ({ history, user, ticket }) => {

    const { _t } = useTranslate();
    const { callApi } = useApiContext();
    const { creator_name, creator_email, history_data, integration_data, date_created, date_updated } = history;
    const queryClient = useQueryClient();

    const assignee = history_data?.assignee;
    const resolved = history_data?.resolved;
    const resolved_by = history_data?.resolved_by;

    const fromIntegration = integration_data?.created_from;
    const textCommentObject = history_data.comments.filter((item) => item.type === 'others');
    const commentValue = textCommentObject[0].comments[0]?.text;
    const userFullName = `${user?.first_name} ${user?.last_name}`;
    const service = ticket?.ticket_category?.integration_data?.service;

    const [lastUpdate, setLastUpdate] = useState(date_updated);
    const [isEditing, setIsEditing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editableComment, setEditableComment] = useState(commentValue);

    const canEdit = 
        (!fromIntegration && !resolved) && 
        (
            (user?.email === creator_email || user?.role === 'admin')  ||
            (userFullName === creator_name)
        )
        
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit();
        }
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const res = await callApi({
            url: `/api/ticket/history/comment?service=${service}`,
            method: 'patch',
            data: { 
                comment: editableComment,
                ticket_id: history.ticket_id,
                action_type: 'comment_updated',
                history_id: integration_data.history_id,
                history_data: history_data,
                fromValetify: true,
                integration_id: ticket?.ticket_category?.integration_data?.integration,
                integration_data: {
                    comment_id: integration_data?.comment_id,
                    history_id: integration_data?.history_id,
                },
            },
        })

        if(res.status === 200) {
            queryClient.refetchQueries('tickets');
        }
        setLastUpdate(new Date());
        setIsSubmitting(false);
        setIsEditing(false);
    }

    const historyStyle = fromIntegration ? Style.history : `${Style.history} shadow-main-100 bg-red`;
    const resolvedStyle = resolved ? 'border-l-4 border-b-2 border-blue-500 rounded-l-lg' : ''
    return ( 
        <div className={historyStyle + ' ' + resolvedStyle}>
            <div className={Style.general}>
                <div className={Style.info}>
                    <div className={Style.about}>
                        <span className={Style.name}>
                            <Name user={user} target={history?.creator_name} disabledHover={true} />
                        </span>
                        <span className={Style.text}>
                            {_t('ticket.history.commented').toLowerCase()}
                        </span>
                    </div>
                    {fromIntegration && (
                        <span className={Style.from}>{_t('from')} {firstLetterToUpperCase(service)}</span>
                    )}
                </div>
                <div className={Style.details}>
                    {canEdit && (
                        <Icon
                            image={isEditing ? 'x-circle' : 'edit'}
                            color={isEditing ? '#F87171' : '#60A5FA'}
                            className={ Style.editIcon }
                            size={ 17 }
                            onClick={() => setIsEditing(!isEditing)}
                        />
                    )}
                    <Time date={date_created} />
                </div>

            </div>
                <hr className={Style.divider} />
                {isEditing ? (
                    <form onSubmit={handleSubmit} className='relative'>
                        <textarea 
                            className="w-full h-20 px-3 py-2 text-base resize-none text-gray-700 placeholder-gray-600 border rounded-lg focus:outline-none focus:ring-1 focus:ring-vibrant-300 transition-shadow duration-200 ease-in-out placeholder:italic placeholder:text-slate-400"
                            value={editableComment} 
                            onChange={e => setEditableComment(e.target.value)} 
                            onKeyDown={handleKeyDown}
                            disabled={isSubmitting} 
                        />
                        <button type="submit" disabled={isSubmitting} className={Style.submitBtn}>
                            {isSubmitting && (
                                <div className="absolute inset-0 flex items-center justify-center bg-gray-100 opacity-50">
                                    <Loader />
                                </div>
                            )}
                            {!isSubmitting && 'Save'}
                        </button>
                    </form>
                ) : (
                    <div className={Style.comment}>
                        {parseComments(history_data?.comments)}
                    </div>
                )}
                {assignee && (
                    <div className={Style.assigned}>
                        <Icon
                            image='user-check'
                            className={''}
                            size={ 17 }
                            onClick={() => setIsEditing(!isEditing)}
                        />
                        <div className={`flex items-center gap-1 ${resolved ? 'line-through' : ''}` }>            
                            {_t('assignee.to')} <Name user={user} target={assignee} />                                                     
                        </div>
                    </div>
                )}
                {(history.action_type === 'comment_updated' && !resolved) && !isSubmitting && (
                    <Time date={lastUpdate} lastEdit={true} className={Style.editInfo} />
                )}
                {resolved && (
                    <span className={Style.editInfo + ' flex-items-center gap-1'}>
                        <p>
                            {_t('ticket.history.resolved_by')} <Name user={user} target={resolved_by} /> {lastUpdate}
                        </p>
                    </span>
                )}

        </div>
    )
};
