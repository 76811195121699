import React from 'react';
import { Form, Animate, usePermissions } from 'components/lib';
import { useTranslate } from 'app/translations';

export const UserForm = (props) => {
    const { _t } = useTranslate();
    const permissions = usePermissions();

    const { refetch } = props;
    const { id, first_name, last_name, is_contact, phone, position, permission, email } =
        props?.user?.data;

    const formData = {
        first_name: {
            label: _t('firstName.label'),
            type: 'text',
            required: false,
            value: first_name || '',
        },
        last_name: {
            label: _t('lastName.label'),
            type: 'text',
            required: false,
            value: last_name,
        },
        email: {
            label: _t('email.label'),
            type: 'email',
            required: false,
            value: email || '',
        },
        ...(is_contact && {
            phone: {
                label: _t('phone.label'),
                type: 'text',
                required: false,
                value: phone || '',
            },
        }),
        ...(is_contact && {
            position: {
                label: _t('position.label'),
                type: 'text',
                required: false,
                value: position || '',
            },
        }),
        ...(!is_contact &&
            permissions?.data?.list && {
                permission: {
                    label: _t('permission.label'),
                    type: permission === 'owner' ? null : 'select',
                    options: permissions?.data?.list
                        ?.map((x) => {
                            return { value: x.value, label: _t(`permissions.${x.value}`) };
                        })
                        .filter((x) => x.value !== 'owner'),
                    default: permission,
                },
            }),
    };

    return (
        <Animate>
            {permissions?.data?.list && (
                <Form
                    data={formData}
                    injectedData={{ id }}
                    callback={(res) => {
                        if (res.status === 200) {
                            refetch();
                        }
                    }}
                    buttonText={_t('save')}	
                    url={`/api/user`}
                    method="PATCH"
                />
            )}
        </Animate>
    );
};
