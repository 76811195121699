/***
 *
 *   Pannel
 *   Display an overlay pannel anywhere in your application by calling
 *   context.pannel.show() with an object containing the following props
 *
 *   PROPS
 *   component: React component to display in the pannel
 *   styles: CSS styles to apply to the pannel
 *
 **********/

import { useContext } from 'react';
import { ViewContext, Icon } from 'components/lib';
import { CSSTransition } from 'react-transition-group';
import './pannel.scss';

export function Pannel(props) {
    const context = useContext(ViewContext);

    const styles = {
        ...props.styles,
    }

    return (
        <CSSTransition in appear timeout={0} classNames="pannel">
            <div
                className="pannel"
                onClick={(e) => e.target === e.currentTarget && context.pannel.hide(true)}>
                <div className="pannel-content" style={styles}>
                    {props.component}
                    <button
                        type="button"
                        className="close-btn"
                        onClick={(e) => context.pannel.hide(true)}>
                        <Icon image={'x'} size={24} />
                    </button>
                </div>
            </div>
        </CSSTransition>
    );
}
