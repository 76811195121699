import { Home } from 'views/website/home';
import { Contact } from 'views/website/contact';
import { Pricing } from 'views/website/pricing';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';
import { _t } from '../app/translations';
import { AccountProjectPage } from 'views/website/account/project';

const Routes = [
    {
        path: '/',
        view: Home,
        layout: 'home',
        title: 'Valetify',
    },
    {
        path: '/contact',
        view: Contact,
        layout: 'home',
        title: _t('contact.label.one'),
    },
    {
        path: '/pricing',
        view: Pricing,
        layout: 'home',
        title: _t('pricing.label'),
    },
    {
        path: '/privacy',
        view: Privacy,
        layout: 'home',
        title: _t('privacy.label'),
    },
    {
        path: '/terms',
        view: Terms,
        layout: 'home',
        title: _t('terms.label'),
    },
    {
        path: '/project/:name',
        view: AccountProjectPage,
        layout: 'home',
        title: 'Request',
    },
];

export default Routes;
