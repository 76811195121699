/***
 *
 *   PASSWORD
 *   Update the users password
 *
 *   if the user has a password show old and new inputs
 *   if no existing password (eg. in case of social signin)
 *   then allow the user to set a password on their account
 *
 **********/

import React, { useContext, useState } from 'react';
import { SettingsWrapper, Animate, Card, Form, Message } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';

export function Password(props) {
    const { _t } = useTranslate();
    const context = useContext(AuthContext);
    const [done, setDone] = useState(false);

    return (
        <SettingsWrapper>
            <Animate>
                <Card title={_t('password.update')} restrictWidth className={props.className}>
                    {!done ? (
                        <Form
                            url="/api/user/password"
                            method="PUT"
                            buttonText={_t('password.save')}
                            data={{
                                ...(context.user.has_password && {
                                    oldpassword: {
                                        label: _t('password.old'),
                                        type: 'password',
                                        required: true,
                                    },
                                    has_password: {
                                        type: 'hidden',
                                        value: true,
                                    },
                                }),
                                newpassword: {
                                    label: _t('password.createNew'),
                                    type: 'password',
                                    required: true,
                                    complexPassword: true,
                                },
                                confirmPassword: {
                                    label: _t('password.confirmNew'),
                                    type: 'password',
                                    required: true,
                                    complexPassword: true,
                                },
                            }}
                            callback={() => {
                                setDone(true);
                                context.update({ has_password: true });
                            }}
                        />
                    ) : (
                        <Message
                            title={_t('password.saved')}
                            type="success"
                            text={_t('password.newSucess')}
                        />
                    )}
                </Card>
            </Animate>
        </SettingsWrapper>
    );
}
