import { createContext, useContext, useEffect } from 'react';
import * as websocket from '../websocket';

export const AppWebsocketContext = createContext(null);

// Store the schema connections
let nextConnectionKey = 0;

const channelConnections = {
    // connectionKey: { channelKey, onUpdate, onDisconnect, onConnect }
};

// Connection namespace
const namespace = '/app';

// Provider
export const AppWebsocketContextProvider = ({ children }) => {
    useEffect(() => {
        listen(dispatchSchemaEvent);

        return () => {
            stopListen(dispatchSchemaEvent);
        }
    }, []);

    // Connect to the schema, and return a connection key
    function connectToChannel(channelKey, methods) {
        const connectionKey = channelKey + nextConnectionKey;

        websocket.connectToChannel(channelKey, namespace);
        channelConnections[connectionKey] = { channelKey, methods };

        nextConnectionKey++;

        return connectionKey;
    }

    function disconnectFromChannel(connectionKey) {
        const channelConnection = channelConnections[connectionKey];

        if(typeof channelConnection === 'undefined') {
            return;
        }

        // Check if there are other connections to the same channel
        const otherConnections = Object.values(channelConnections).filter((c) => {
            return channelConnection.channelKey === c.channelKey;
        });

        // If there are no other connections, disconnect from the channel
        if(otherConnections.length === 0) {
            websocket.disconnectFromChannel(channelConnection.channelKey, namespace);
        }

        // Delete the connection
        delete channelConnections[connectionKey];
    }
    
    function dispatchSchemaEvent(event, data) {
        Object.values(channelConnections).forEach((channelConnection) => {
            const { channelKey, methods } = channelConnection;
            const { onUpdate, onDisconnect, onConnect } = methods;

            if(data.channel === channelKey) {
                switch(event) {
                    case "channel:connected":
                        onConnect(data.payload);
                        break;
                    case "channel:disconnected":
                        onDisconnect(data.payload);
                        break;
                    case "channel:update":
                        onUpdate(data.payload);
                        break;
                }
            }
        });
    }

    function listen(callback) {
        websocket.listen(callback, namespace);
    }

    function stopListen(callback) {
        websocket.stopListen(callback, namespace);
    }

    const value = {
        // Connect to the websocket in the "app" namespace
        namespace: namespace,
        socket: websocket.connect(namespace),
        connectToChannel: connectToChannel,
        disconnectFromChannel: disconnectFromChannel,
        listen: listen,
        stopListen: stopListen,
    }

    return <AppWebsocketContext.Provider value={value}>{children}</AppWebsocketContext.Provider>;
};

export const useAppWebsocketContext = () => {
    return useContext(AppWebsocketContext);
};