import { getUserCache } from "utilities/cache";

const { io } = require("socket.io-client");

// To store socket connections by namespaces
const sockets = {};

export function connect(namespace = '/') {
    if(typeof sockets[namespace] === 'undefined') {
        const protocol = process.env.REACT_APP_WS_SECURE == 1 ? 'wss' : 'ws';
        const host = process.env.REACT_APP_WS_HOST;
        const port = process.env.REACT_APP_WS_PORT;

        sockets[namespace] = io(`${protocol}://${host}:${port}${namespace}`);
    }

    return sockets[namespace];
}

/**
 * Connect to a schema channel
 * 
 * @param {string} channelKey 
 */
export function connectToChannel(channelKey, namespace = '/') {
    const user = getUserCache();

    // Get the socket connection
    const socket = connect(namespace);

    // Build the event
    const event = `channel:connect`;
    const channel = channelKey;
    const payload = {
        jwt: !user || user === 'undefined' ? null : user.token,
    };

    // Emit the event
    socket.emit(event, { event, channel, payload });
}

/**	
 * Disconnect from a schema channel
 * 
 * @param {string} channelKey
 * @param {string} namespace
 * @returns {void}
 */
export function disconnectFromChannel(channelKey, namespace = '/') {
    // Get the socket connection
    const socket = connect(namespace);

    // Build the event
    const event = `channel:disconnect`;
    const channel = channelKey;
    const payload = {};

    // Emit the event
    socket.emit(event, { event, channel, payload });
}

export function listen(callback, namespace = '/') {
    // Get the socket connection
    const socket = connect(namespace);

    socket.onAny(callback);
}

export function stopListen(callback, namespace = '/') {
    // Get the socket connection
    const socket = connect(namespace);

    socket.offAny(callback);
}