/***
*
*   FORGOT PASSWORD
*   Trigger a password reset process
*
**********/

import React from 'react';
import { Animate, Row, Form, Card } from 'components/lib';
import { useTranslate } from 'app/translations';

export function ForgotPassword(props){
  const { _t } = useTranslate();

  return(
    <Animate type='pop'>
      <Row title={_t('password.reset.label')}>
        <Card restrictWidth center>

          <p className='mb-5'>{_t('password.forgot.info')}</p>

          <Form
            data={{
              email: {
                label: _t('email.label'),
                type: 'email',
                required: true
              }
            }}
            url='/api/auth/password/reset/request'
            auth={false}
            method='POST'
            buttonText={_t('password.reset.text')}
          />

        </Card>
      </Row>
    </Animate>
  );
}
