import Cookies from 'js-cookie';
import { getSubdomain } from './routes';
import settings from '../settings';

const { client_url_main } = settings;

export const getUserCache = (withCookie = true) => {
    const user = localStorage.getItem('user');
    const userCookie = withCookie ? Cookies.get('user') : null;

    if (userCookie) {
        // set user cookie to local storage and make sure subdomain matches
        const parsedUserCookie = JSON.parse(userCookie);

        if (validateUserDomain(parsedUserCookie)) {
            setUserStorage(parsedUserCookie);
            deleteUserCookie();
        }

        return parsedUserCookie;
    } else if (user) {
        if (userCookie) {
            // if user local storage and user cookie exist, delete user cookie
            deleteUserCookie();
        }
        return JSON.parse(user);
    }
};

export const setUserStorage = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

export const deleteUserCookie = () => {
    Cookies.remove('user', { domain: client_url_main });
};

export const validateUserDomain = (user) => {
    if (!user || user === 'undefined') return false;

    const subDomain = getSubdomain();
    const accountDomain = user.accounts.find((account) => account.id === user.account_id).domain;

    return subDomain === accountDomain;
};
