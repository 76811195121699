const Style = {
    wrapper: 'flex flex-col overflow-x-auto pt-2 overflow-y-hidden pb-5',
    container: 'flex w-fit px-4 pb-2 h-full',
    title: 'flex text-md font-semibold py-2 px-6',
    icon: 'mr-2',
    media: 'w-[150px] p-2 h-32 relative rounded-2xl',
    link: "relative rounded-2xl",
    img: 'w-full h-full object-cover rounded-2xl shadow-md cursor-pointer',
    overlay: 'rounded-2xl absolute inset-0 bg-black opacity-0 hover:opacity-50 flex items-center justify-center transition-all duration-500 ease-in-out',
};
export default Style;