/***
 *
 *   BREADCRUMBS CONTEXT
 *
 *   VALUES:
 *   - params : received params
 *   - pushParams : push params to context
 *   - paramsList : list of params
 *
 **********/

import { useState, createContext, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { routes } from 'app/app';

export const BreadcrumbsContext = createContext(null);

export const BreadcrumbsContextProvider = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;
    const pathParts = pathname.split('/').filter(Boolean);
    const routerParams = useParams();

    const [params, setParams] = useState({});
    // In case we want to force an active url and ignore its child paths
    const [forcedActiveUrl, setForcedActiveUrl] = useState(null);

    const pushParams = (params) => {
        setParams((prev) => ({
            ...prev,
            ...params,
        }));
    };

    const formatUrl = (index) => {
        let url = '/';
        for (let i = 0; i <= index; i++) {
            url += pathParts[i];
            if (i < index) {
                url += '/';
            }
        }
        return url;
    };

    const paramsList = pathParts.map((part, index) => {
        const url = formatUrl(index);

        let title = '';

        title = routes.find((r) => r.path === url)?.title;

        if (!title) {
            // find part if in router params
            const paramsKey = Object.keys(routerParams).find((p) => routerParams[p] === part);

            if (paramsKey) {
                title = params[paramsKey];
            }
        }

        return {
            name: title,
            url: url,
            isActive: forcedActiveUrl === url
        };
    });
    
    const value = {
        params,
        pushParams,
        paramsList,
        forcedActiveUrl,
        setForcedActiveUrl,
    };

    return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
};

export const useBreadcrumbsContext = () => {
    return useContext(BreadcrumbsContext);
};
